import { TBonusActiveRequest, TBonusCancelRequest, TBonusRequest } from "src/@types/bonus";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/bonuses";
const routeCancel = "/v1/clients/:id/cancel-bonus/:bonusId";
const projectBonusListRoute = "/v1/projects/bonus/list";
const routeActive = "/v1/clients/:id/transactions/activate-client-bonus";

const getById = async (data: TBonusRequest) => GenericDAO.getByIdWithParams(route, parseInt(data.clientId), [
    {
        name: 'page',
        value: data.page as any
    },
    {
        name: 'pageSize',
        value: data.pageSize as any
    }
], true);

const active = async (data: TBonusActiveRequest) => GenericDAO.putWithId(routeActive, {
    clientId: data.clientId
}, {
    bonusId: data.bonusId,
    value: data.value
}, true);

const cancel = async (data: TBonusCancelRequest) => GenericDAO.putWithTwoId(routeCancel, "bonusId", data, {
    notes: data.notes
}, true);

const getList = async () => GenericDAO.get(projectBonusListRoute, undefined, undefined, true);

export default {
    getById,
    cancel,
    getList,
    active
};