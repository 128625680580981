import { Button, Dialog, Flex, Spinner, Text, TextField } from "@radix-ui/themes";
import { jwtDecode } from "jwt-decode";
import { BookmarkIcon } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { TLoginFormRequest } from "src/@types/auth";
import { TUserInfoResponse } from "src/@types/user";
import Auth from "src/infraestructure/services/api/Auth";
import Projects from "src/infraestructure/services/api/Projects";

export type TModalLogin = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const ModalLogin = ({ open, setOpen }: TModalLogin) => {

    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TLoginFormRequest>()
    const onSubmit: SubmitHandler<TLoginFormRequest> = async (data) => {
        setLoading(true);
        const allProjects = await Projects.get();
        // setProjects(allProjects.data);

        const userLogin = await Auth.save({
            email: data.email,
            password: data.password
        });

        if (userLogin && userLogin.token) {
            const token = userLogin.token as string;
            const decodeToken = jwtDecode(token);
            window.localStorage.setItem("@App:token", token);

            const getUserInfo = await Auth.getMe();

            const userInfoData: TUserInfoResponse = getUserInfo.data;

            if (userInfoData) {
                if (userInfoData.blocked) {
                    toast.error('Este usuário está bloqueado!');
                    window.localStorage.removeItem("@App:token");
                    window.localStorage.removeItem("@App:user");
                    window.localStorage.removeItem("@App:Avatar");
                } else {
                    if (userInfoData.projects.length > 0) {
                        toast.success('Login realizado com sucesso!');
                        window.location.href = "/home";
                        window.localStorage.setItem("@App:Avatar", userInfoData.avatar_url);
                        window.localStorage.setItem("@App:user", JSON.stringify(userInfoData));
                    } else {
                        toast.error('Este usuário não possui vínculo com nenhum projeto.');
                    }
                }
            } else {
                window.localStorage.removeItem("@App:token");
                window.localStorage.removeItem("@App:user");
                window.localStorage.removeItem("@App:Avatar");
            }
        }
        setLoading(false);
        setOpen(false);
    }

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger style={{ display: 'none' }}>
                <Button>{t('Join')}</Button>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px">
                <Dialog.Title>Sessão Expirou</Dialog.Title>
                <Dialog.Description size="2" mb="4">
                    Faça o login novamente
                </Dialog.Description>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <Flex direction="column" gap="3">
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                {t('Email')}
                            </Text>
                            <TextField.Root
                                {...register("email", { required: true })}
                                placeholder="E-mail"
                            />
                        </label>
                        {errors.password &&
                            <p style={{ color: 'red', fontSize: 12 }}>
                                {t('Email_Required')}
                            </p>
                        }
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                {t('Password')}
                            </Text>
                            <TextField.Root
                                type="password"
                                {...register("password", { required: true })}
                                placeholder="Digite sua Senha"
                            />
                        </label>
                        {errors.password &&
                            <p style={{ color: 'red', fontSize: 12 }}>
                                {t('Password_Required')}
                            </p>
                        }
                    </Flex>

                    <Flex gap="3" mt="4" justify="end">
                        {!loading && <Button variant="classic" type="submit">{t('Join')}</Button>}
                        {loading && <Button disabled variant="classic">
                            <Spinner loading>
                                <BookmarkIcon />
                            </Spinner>
                            {t('Join')}
                        </Button>}
                    </Flex>
                </form>
            </Dialog.Content>
        </Dialog.Root>

    );
}

export default ModalLogin;