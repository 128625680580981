import { TReportRequestType } from "src/@types/reports";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/transactions/:type";
const routeSportbook = "/v1/clients/bet-report";

const getReport = async (data: TReportRequestType) => GenericDAO.getByIdWithParamsType(route, data.clientId, data.type, [
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "limit",
        value: data.limit as any,
    }
], );

const getReportBet = async (data: TReportRequestType) => GenericDAO.get(routeSportbook, undefined, [
    {
        name: "betId",
        value: data.betId as any,
    },
    {
        name: "clientId",
        value: data.clientId as any,
    },
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "limit",
        value: data.limit as any,
    }
], );

export default {
    getReport,
    getReportBet
};