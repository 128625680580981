//https://api-0.7games.support/v1/clients/141386354/notes
import React, { useEffect, useState } from 'react';
import TablePaginated from '../TablePaginated';
import Notes from 'src/infraestructure/services/api/Notes';
import { TDefaultResponse } from 'src/@types/transactions';

export type TClientNotesType = {
    userId: string;
}

const ClientNotes = ({ userId }: TClientNotesType) => {

    const [notesList, setNotesList] = useState<TDefaultResponse>();

    useEffect(() => {
        getNotes();
    }, []);

    const getNotes = async () => {
        const get = await Notes.getById({
            clientId: userId
        })

        if (get) {
            setNotesList(get);
        }
    }

    return (
        <>
            <TablePaginated 
                data={notesList?.data} 
                totalItems={notesList?.pagination.totalItems ?? 0}  
                keys={[
                        {
                            name: "id",
                            type: "string"
                        },
                        {
                            name: "note",
                            type: "string"
                        },
                        {
                            name: "modified",
                            type: "string"
                        },
                    ]
                } 
                perPage={10}
                onPageChange={(page: number) => {
                    throw new Error('Function not implemented.');
                }}
            />
        </>
    );
}

export default ClientNotes;