import { Badge, Card, Table } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentSystemConfig } from 'src/@types/admin';
import MainLayout from 'src/components/MainLayout';
import ModalPaymentConfig from 'src/components/ModalPaymentConfig';
import Config from 'src/infraestructure/services/api/Admin/Config';

const AdminConfig = () => {

    const [payments, setPayments] = useState<PaymentSystemConfig[]>();

    const { t } = useTranslation();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const getConfig = await Config.get();

        console.log(getConfig);
        if (getConfig && getConfig.data) {
            console.log(getConfig.data);

            setPayments(getConfig.data.paymentsList);
        }
    }

    const handleFinish = async () => {
        const getConfig = await Config.get();

        console.log(getConfig);
        if (getConfig && getConfig.data) {
            console.log(getConfig.data);

            setPayments(getConfig.data.paymentsList);
        }
    }

    return (
        <MainLayout>
            <Card style={{ padding: 10 }}>
                <h3>{t('Payment_Methods')}</h3>
                <Table.Root>
                    <Table.Header>
                        <Table.Row>
                            <Table.ColumnHeaderCell>{t('Name')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Deposit')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Withdraw')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Order_Deposit')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Order_Withdraw')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Actions')}</Table.ColumnHeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {payments?.map((i, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{i.payment_system}</Table.Cell>
                                    <Table.RowHeaderCell>{i.active_deposit ? <Badge color='green'>Ativo</Badge> : <Badge color='red'>Desativado</Badge>}</Table.RowHeaderCell>
                                    <Table.Cell>{i.active_withdrawal ? <Badge color='green'>Ativo</Badge> : <Badge color='red'>Desativado</Badge>}</Table.Cell>
                                    <Table.Cell>{i.order_deposit}</Table.Cell>
                                    <Table.Cell>{i.order_withdrawal}</Table.Cell>
                                    <Table.Cell>
                                       <ModalPaymentConfig data={i} paymentsCount={payments.length} paymentsList={payments} onFinish={() => handleFinish()} />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table.Root>
            </Card>
        </MainLayout>
    );
}

export default AdminConfig;