import { TBlockUserRequest } from "src/@types/clients";
import GenericDAO from "../GenericDAO";

const route = '/v1/clients/:id/block-status';

const put = async (data: TBlockUserRequest) => GenericDAO.putWithId(route, {
    clientId: data.clientId
}, {
    note: data.note
}, true);

export default {
    put
};
