import { TTransactionsReview } from "src/@types/review";
import GenericDAO from "../../GenericDAO";
import { TUpdateTransaction } from "src/@types/transactions";

const route = "/v1/review/transactions";
const routeUpdate = "/v1/review/update-transaction/:id/:type";

const get = async (data: TTransactionsReview) => GenericDAO.get(route, null, [
    {
        name: "type",
        value: data.type as any,
    },
    {
        name: "status",
        value: data.status as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "limit",
        value: data.limit as any,
    },
], true);

const put = async (data: TUpdateTransaction) => GenericDAO.postByIdWithParamsType(routeUpdate, data.transactionId, data.action, data, undefined, true);


export default {
    get,
    put
};