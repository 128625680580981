import { Avatar, Button, DropdownMenu, Select } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TProjectsResponse } from 'src/@types/projects';
import { MenuList } from 'src/config/menu';
import useUserRole from 'src/hooks/useUserRole';
import Projects from 'src/infraestructure/services/api/Projects';
import useHeaderStyles from 'src/styles/components/headerStyles';
import logo from '../../assets/images/logo.svg';
import Auth from 'src/infraestructure/services/api/Auth';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [currentProject, setCurrentProject] = useState<string>(localStorage.getItem("@App:currentProject") ? JSON.parse(localStorage.getItem("@App:currentProject") || '{}').name : "");
  const { HeaderInner, LeftFlex, LeftFlexAbsolute, BoxLink, HeaderProductLinks, HeaderProfileMenu } = useHeaderStyles();
  const { status, isLoading, data, error, isError } = useQuery({ queryKey: ['projects'], queryFn: () => Projects.get() });
  const [myProjects, setMyProjects] = useState<string[]>();

  const handleChangeProject = (e: string) => {
    setCurrentProject(e);
    localStorage.setItem("@App:currentProject", JSON.stringify(data.data.filter((i: TProjectsResponse) => i.name === e)[0]));
    window.location.href = "/home";
  }

  const navigate = useNavigate();
  const role = useUserRole();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const requestMe = await Auth.getMe();
    if (requestMe) {
      if (requestMe.data) {
        setMyProjects(requestMe.data.projects);
      }
    }
  }

  const menuList = MenuList;

  return (
    <div className={HeaderInner}>
      <div className={LeftFlex}>
        <div className={LeftFlexAbsolute}>
          <div className={BoxLink} onClick={() => {
            navigate('/home');
          }}>
            <img src={logo} />
          </div>
          <div className={HeaderProductLinks}>
            {menuList.map((i, index) => {
              if (i.permissions.includes(role)) {
                return (
                  <DropdownMenu.Root key={index}>
                    <DropdownMenu.Trigger>
                      <Button variant="soft" style={{ background: 'unset' }}>
                        {t(i.name)}
                        <DropdownMenu.TriggerIcon />
                      </Button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      {i.submenus.map((sb, idx) => {
                        if (sb.permissions.includes(role)) {
                          return (
                            <DropdownMenu.Item key={idx} onClick={() => {
                              navigate(sb.path);
                            }}>{t(sb.name)}</DropdownMenu.Item>
                          )
                        } else {
                          return (
                            <div key={idx}></div>
                          )
                        }
                      })}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                );
              } else {
                return (
                  <div key={index}></div>
                )
              }
            })}
          </div>
        </div>
      </div>
      <div className={HeaderProfileMenu}>
        {!isLoading && !isError && data.data && myProjects && myProjects?.length > 0 && <Select.Root value={currentProject} onValueChange={(e) => handleChangeProject(e)} size={"3"}>
          <Select.Trigger />
          <Select.Content style={{ width: 300 }}>
            <Select.Group>
              <Select.Label>{t('Projects')}</Select.Label>
              {data.data.filter((s: { name: string; id: string }) => myProjects?.includes(s.id)).map((i: TProjectsResponse, index: string) => {
                return (
                  <Select.Item key={index} value={i.name}>{i.name}</Select.Item>
                )
              })}
            </Select.Group>
          </Select.Content>
        </Select.Root>}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button style={{ background: 'transparent' }}>
              <Avatar fallback="A" src={window.localStorage.getItem("@App:Avatar") ?? ""} radius='full' />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content style={{ marginTop: 10 }}>
            <DropdownMenu.Item shortcut="⌘ E">Editar Perfil</DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => {
              navigate('/login');
              window.localStorage.removeItem("@App:currentProject");
              window.localStorage.removeItem("@App:token");
              window.localStorage.removeItem("@App:user");
              toast.success(`Usuário logado com sucesso!`);
            }} shortcut="⌘ ⌫" color="red">
              {t('Sair')}
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
}

export default Header;