import { Badge, Button, Dialog, Flex, IconButton, Spinner, Tooltip } from "@radix-ui/themes";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { EyeIcon, Receipt, ReceiptCent, Undo2 } from "lucide-react";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TWithdrawPixtoPayResponse } from "src/@types/deposits";
import Filter, { FTypes } from "src/components/Filter";
import MainLayout from "src/components/MainLayout";
import ModalLogs from "src/components/ModalLogs";
import ModalReceipt from "src/components/ModalReceipt";
import PaagPDFViewer from "src/components/PaagPDFViewer";
import TablePaginated from "src/components/TablePaginated";
import PaymentMethod from "src/infraestructure/services/api/PaymentMethod";
import { getColorFromStatus } from "src/utils";

const PaymentMethods = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [items, setItems] = useState<any[]>([]);
  const [filteres, setFilters] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [loadingReceipt, setLoadingReceit] = useState<string>("");

  const { t } = useTranslation();

  const getReceipt = async (id: string, method: string, type: string) => {
    setLoadingReceit(id);
    let data: any = {};

    if (method === "paybrokers") {
      data = {
        typeId: method,
        transactionId: id,
        type: type === 'withdrawals' ? 'withdraw' : ''
      };
    }
    if (method === "pay2free") {
      data = {
        typeId: method,
        transactionId: id,
        type: type === 'withdrawals' ? 'withdraw' : 'deposit'
      };
    }
    if (method === "pixtopay") {
      data = {
        typeId: method,
        transactionId: id,
      };
    }

    if (method === "pagfast") {
      data = {
        typeId: method,
        transactionId: id,
      };
    }

    const requestReceipt = await PaymentMethod.getReceiptById(data)

    if (requestReceipt && requestReceipt.data) {
      if (requestReceipt.data.pdf_url) {
        window.open(requestReceipt.data.pdf_url, '_blank');
      }
    }

    if (requestReceipt && requestReceipt.data) {
      if (requestReceipt.data.url_pdf) {
        window.open(requestReceipt.data.url_pdf, '_blank');
      }
    }

    if (requestReceipt) {
      if (requestReceipt.pdf_url) {
        window.open(requestReceipt.pdf_url, '_blank');
      }
    }

    if (requestReceipt && requestReceipt.pdf_base64) {
      let pdfWindow: any = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(requestReceipt.pdf_base64) + "'></iframe>"
      )
    }

    setLoadingReceit("");
  }

  const getReceiptPDF = async (id: string, base64: string) => {
    if (base64) {
      setLoadingReceit(id);
      let pdfWindow: any = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64) + "'></iframe>"
      )

      setLoadingReceit("");
    }
  }

  const getKeysFromTables = (typeId: string, type: string, search: string) => {
    if (typeId === "pay2free") {
      if (type === "deposits") {
        return [
          {
            name: "txid",
            type: "string"
          },
          {
            name: "clientName",
            type: "string"
          },
          {
            name: "payerName",
            type: "string"
          },
          {
            name: "value",
            type: "money"
          },
          {
            name: "createdAt",
            type: "date"
          },
          {
            name: "status",
            type: "tag"
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              return (
                <div>
                  <ModalReceipt type={"deposits"} method={"pay2free"} selectedId={selectedId} transactionId={String(response.txid)} children={() => {
                    return (
                      <IconButton radius="full" color="violet" onClick={() => {
                        setSelectedId(String(response.txid));
                      }}>
                        <EyeIcon size={18} />
                      </IconButton>
                    )
                  }} />
                </div>
              )
            }
          }
        ]
      } else {
        return [
          {
            name: "transactionId",
            type: "string"
          },
          {
            name: "clientName",
            type: "string"
          },
          {
            name: "payeeName",
            type: "string"
          },
          {
            name: "bankName",
            type: "string"
          },
          {
            name: "value",
            type: "money"
          },
          {
            name: "createdAt",
            type: "date"
          },
          {
            name: "status",
            type: "tag"
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              if (response.status === "completed") {
                return (
                  <Tooltip content="Comprovante">
                    <IconButton onClick={() => {
                      if (loadingReceipt !== String(response.transactionId)) {
                        getReceipt(String(response.transactionId), typeId, type);
                      }
                    }} radius="full">
                      {loadingReceipt !== String(response.transactionId) && <ReceiptCent size={15} />}
                      {loadingReceipt === String(response.transactionId) && <Spinner />}
                    </IconButton>
                  </Tooltip>
                )
              } else {
                return (
                  <div>
                    <ModalReceipt type={"withdrawals"} method={"pay2free"} selectedId={selectedId} transactionId={String(response.transactionId)} children={() => {
                      return (
                        <IconButton radius="full" color="violet" onClick={() => {
                          setSelectedId(String(response.transactionId));
                        }}>
                          <EyeIcon size={18} />
                        </IconButton>
                      )
                    }} />
                  </div>
                )
              }
            }
          }
        ]
      }
    }
    if (typeId === "pixtopay") {
      if (type === "deposits") {
        if (search === "transactionId") {
          return [
            {
              name: "cpf",
              type: "string"
            },
            {
              name: "data",
              type: "string"
            },
            {
              name: "nome",
              type: "string"
            },
            {
              name: "email",
              type: "string"
            },
            {
              name: "phone_number",
              type: "string"
            },
            {
              name: "pix_e2e_id",
              type: "string"
            },
            {
              name: "pix_paid_at",
              type: "date"
            },
            {
              name: "pix_payer_bank_code",
              type: "string"
            },
            {
              name: "pix_payer_document_number",
              type: "string"
            },
            {
              name: "pix_payer_name",
              type: "string"
            },
            {
              name: "pix_provider",
              type: "string"
            },
            {
              name: "status",
              type: "tag"
            },
            {
              name: "actions",
              type: "string",
              children: (response: any) => {
                return (
                  <>
                    <Tooltip content="Comprovante">
                      <IconButton onClick={() => {
                        if (loadingReceipt !== String(response.pix_e2e_id)) {
                          getReceiptPDF(response.pix_e2e_id, response.receipt_pdf);
                        }
                      }} radius="full">
                        {loadingReceipt !== String(response.pix_e2e_id) && <ReceiptCent size={15} />}
                        {loadingReceipt === String(response.pix_e2e_id) && <Spinner />}
                      </IconButton>
                    </Tooltip>
                  </>
                )
              }
            }
          ]
        } else {
          return [
            {
              name: "id",
              type: "string"
            },
            {
              name: "nome",
              type: "string"
            },
            {
              name: "cpf",
              type: "string"
            },
            {
              name: "credito",
              type: "money"
            },
            {
              name: "data",
              type: "string"
            },
            {
              name: "pix_payer_name",
              type: "string"
            },
            {
              name: "pix_payer_document_number",
              type: "string"
            },
            {
              name: "status",
              type: "tag"
            },
            {
              name: "actions",
              type: "string",
              children: (response: any) => {
                return (
                  <div>
                    <ModalReceipt type={"deposits"} method={"pixtopay"} selectedId={selectedId} transactionId={String(response.id)} children={() => {
                      return (
                        <IconButton radius="full" color="violet" onClick={() => {
                          setSelectedId(String(response.id));
                        }}>
                          <EyeIcon size={18} />
                        </IconButton>
                      )
                    }} />
                  </div>
                )
                // if (response.status === "Returned") {
                //   return (<>
                //     <Tooltip content="Comprovante">
                //       <IconButton onClick={() => {
                //         if (loadingReceipt !== String(response.transaction_id)) {
                //           getReceipt(String(response.transaction_id), typeId, type);
                //         }
                //       }} radius="full">
                //         {loadingReceipt !== String(response.transaction_id) && <ReceiptCent size={15} />}
                //         {loadingReceipt === String(response.transaction_id) && <Spinner />}
                //       </IconButton>
                //     </Tooltip>
                //   </>);
                // } else {
                //   return (
                //     <div>
                //       <ModalReceipt type={"deposits"} method={"pixtopay"} selectedId={selectedId} transactionId={String(response.id)} children={() => {
                //         return (
                //           <IconButton radius="full" color="violet" onClick={() => {
                //             setSelectedId(String(response.id));
                //           }}>
                //             <EyeIcon size={18} />
                //           </IconButton>
                //         )
                //       }} />
                //     </div>
                //   )
                // }
              }
            }
          ]
        }
      } else {
        return [
          {
            name: "id",
            type: "string",
          },
          {
            name: "transaction_id",
            type: "string",
          },
          {
            name: "nome",
            type: "string",
          },
          {
            name: "cpf",
            type: "string",
          },
          {
            name: "pix_key",
            type: "string",
          },
          {
            name: "banco",
            type: "string",
          },
          {
            name: "data_registro",
            type: "string",
          },
          {
            name: "credito",
            type: "money",
          },
          {
            name: "status",
            type: "tag",
            children: ((response: TWithdrawPixtoPayResponse) => {
               if(response.status !== "CANCELED") {
                  return(
                    <Badge color={getColorFromStatus(t(response.status))}>{t(response.status)}</Badge>
                  );
               } else {
                  return(
                    <div>
                      {response.logInfo && <ModalLogs status={response.status} amount={response.credito} logList={response.logInfo} />}
                    </div>
                  );
               }
            })
          },
          {
            name: "actions",
            type: "string",
            children: (response: TWithdrawPixtoPayResponse) => {
              return (
                <Flex direction={"row"} gap={"3"}>
                  <Tooltip content="Comprovante">
                    <IconButton onClick={() => {
                      if (loadingReceipt !== String(response.id)) {
                        getReceipt(String(response.id), typeId, type);
                      }
                    }} radius="full">
                      {loadingReceipt !== String(response.id) && <ReceiptCent size={15} />}
                      {loadingReceipt === String(response.id) && <Spinner />}
                    </IconButton>
                  </Tooltip>
                </Flex>
              );
            }
          }
        ]
      }
    }
    if (typeId === "paybrokers") {
      if (type === "deposits") {
        return [
          {
            name: "_id",
            type: "string",
          },
          {
            name: "buyer_name",
            type: "string",
          },
          {
            name: "buyer_document",
            type: "string",
          },
          {
            name: "bank",
            type: "string",
          },
          {
            name: "createdAt",
            type: "date",
          },
          {
            name: "delivered_at",
            type: "date",
          },
          {
            name: "paid_at",
            type: "date",
          },
          {
            name: "value",
            type: "money",
          },
          {
            name: "status",
            type: "tag",
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              return (
                <div onClick={() => {
                  if (loadingReceipt !== String(response._id)) {
                    getReceipt(String(response._id), typeId, type);
                  }
                }}>
                  {loadingReceipt !== String(response._id) && <Receipt />}
                  {loadingReceipt === String(response._id) && <Spinner />}
                </div>
              )
            }
          }
        ];
      } else {
        return [
          {
            name: "_id",
            type: "string",
          },
          {
            name: "receiver_name",
            type: "string",
          },
          {
            name: "receiver_document",
            type: "string",
          },
          {
            name: "bank",
            type: "string",
          },
          {
            name: "createdAt",
            type: "date",
          },
          {
            name: "delivered_at",
            type: "date",
          },
          {
            name: "paid_at",
            type: "date",
          },
          {
            name: "value",
            type: "money",
          },
          {
            name: "status",
            type: "tag",
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              return (
                <div onClick={() => {
                  if (loadingReceipt !== String(response._id)) {
                    getReceipt(String(response._id), typeId, type);
                  }
                }}>
                  {loadingReceipt !== String(response._id) && <Receipt />}
                  {loadingReceipt === String(response._id) && <Spinner />}
                </div>
              )
            }
          }
        ];
      }
    }
    if (typeId === "pagfast") {
      if (type === "deposits") {
        return [
          {
            name: "id",
            type: "object",
          },
          {
            name: "payer_name",
            type: "string",
          },
          {
            name: "payer_cpf",
            type: "string",
          },
          {
            name: "date",
            type: "date",
          },
          {
            name: "stateRegisteredDate",
            type: "date",
          },
          {
            name: "amount",
            type: "money",
          },
          {
            name: "state",
            type: "tag",
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              return (
                <Tooltip content="Comprovante">
                  <IconButton onClick={() => {
                    if (loadingReceipt !== String(response.id)) {
                      getReceipt(String(response.id), typeId, type);
                    }
                  }} radius="full">
                    {loadingReceipt !== String(response.id) && <ReceiptCent size={15} />}
                    {loadingReceipt === String(response.id) && <Spinner />}
                  </IconButton>
                </Tooltip>
              )
            }
          }
        ];
      } else {
        return [
          {
            name: "id",
            type: "object",
          },
          {
            name: "payment_recipient_name",
            type: "string",
          },
          {
            name: "payment_recipient_cpf",
            type: "string",
          },
          {
            name: "date",
            type: "date",
          },
          {
            name: "stateRegisteredDate",
            type: "date",
          },
          {
            name: "amount",
            type: "money",
          },
          {
            name: "state",
            type: "tag",
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              return (
                <Tooltip content="Comprovante">
                  <IconButton onClick={() => {
                    if (loadingReceipt !== String(response.id)) {
                      getReceipt(String(response.id), typeId, type);
                    }
                  }} radius="full">
                    {loadingReceipt !== String(response.id) && <ReceiptCent size={15} />}
                    {loadingReceipt === String(response.id) && <Spinner />}
                  </IconButton>
                </Tooltip>
              )
            }
          }
        ];
      }
    }

    if (typeId === "paag") {
      if (type === "deposits") {
        return [
          {
            name: "id",
            type: "object",
          },
          {
            name: "name",
            type: "string",
            children: ((response: any) => {
              return (
                <div>{`${response.first_name} ${response.last_name}`}</div>
              )
            })
          },
          {
            name: "document_number",
            type: "string",
          },
          {
            name: "created_at",
            type: "date",
          },
          {
            name: "updated_at",
            type: "date",
          },
          {
            name: "amount",
            type: "money",
            children: ((response: any) => {
              return (
                <div>R$ {response.transaction_events[0].amount}</div>
              )
            })
          },
          {
            name: "status",
            type: "tag",
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              return (
                <div style={{ display: 'flex', gap: 10 }}>
                  <Dialog.Root>
                    <Tooltip content="Comprovante">
                      <Dialog.Trigger>
                        <IconButton radius="full">
                          <ReceiptCent size={15} />
                        </IconButton>
                      </Dialog.Trigger>
                    </Tooltip>

                    <Dialog.Content maxWidth="50%">
                      <Dialog.Title>{``}</Dialog.Title>

                      <Flex direction="column" gap="3" maxHeight={'1000px'}>
                        <PaagPDFViewer transactionId={response.id} type={"deposits"} />
                      </Flex>

                      <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                          <Button variant="classic" color="gray">
                            {t('Close')}
                          </Button>
                        </Dialog.Close>
                      </Flex>
                    </Dialog.Content>
                  </Dialog.Root>

                  {response.error ? <Dialog.Root>
                    <Tooltip content="Reembolso">
                      <Dialog.Trigger>
                        <IconButton color={"yellow"} radius="full">
                          <Undo2 size={15} />
                        </IconButton>
                      </Dialog.Trigger>
                    </Tooltip>

                    <Dialog.Content maxWidth="50%">
                      <Dialog.Title>{``}</Dialog.Title>

                      <Flex direction="column" gap="3" maxHeight={'1000px'}>
                        <PaagPDFViewer transactionId={response.id} type={"deposits"} isRefunded={true} />
                      </Flex>

                      <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                          <Button variant="classic" color="gray">
                            {t('Close')}
                          </Button>
                        </Dialog.Close>
                      </Flex>
                    </Dialog.Content>
                  </Dialog.Root> : null}
                </div>
              )
            }
          }
        ]
      } else {
        return [
          {
            name: "id",
            type: "object",
          },
          {
            name: "name",
            type: "string",
            children: ((response: any) => {
              return (
                <div>{`${response.first_name} ${response.last_name}`}</div>
              )
            })
          },
          {
            name: "document_number",
            type: "string",
          },
          {
            name: "created_at",
            type: "date",
          },
          {
            name: "updated_at",
            type: "date",
          },
          {
            name: "amount",
            type: "money",
            children: ((response: any) => {
              return (
                <div>R$ {response.transaction_events[0].amount}</div>
              )
            })
          },
          {
            name: "status",
            type: "tag",
          },
          {
            name: "actions",
            type: "string",
            children: (response: any) => {
              return (
                <>
                  <Dialog.Root>
                    <Tooltip content="Comprovante">
                      <Dialog.Trigger>
                        <IconButton radius="full">
                          <ReceiptCent size={15} />
                        </IconButton>
                      </Dialog.Trigger>
                    </Tooltip>

                    <Dialog.Content maxWidth="50%">
                      <Dialog.Title>{``}</Dialog.Title>

                      <Flex direction="column" gap="3" maxHeight={'1000px'}>
                        <PaagPDFViewer transactionId={response.id} type={"withdraw"} />
                      </Flex>

                      <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                          <Button variant="classic" color="gray">
                            {t('Close')}
                          </Button>
                        </Dialog.Close>
                      </Flex>
                    </Dialog.Content>
                  </Dialog.Root>
                </>
                // <Tooltip content="Comprovante">
                //   <IconButton onClick={() => {
                //     if (loadingReceipt !== String(response.id)) {
                //       getReceipt(String(response.id), typeId, type);
                //     }
                //   }} radius="full">
                //     {loadingReceipt !== String(response.id) && <ReceiptCent size={15} />}
                //     {loadingReceipt === String(response.id) && <Spinner />}
                //   </IconButton>
                // </Tooltip>
              )
            }
          }
        ]
      }
    }

    return [];
  }

  const nextPage = async () => {
    let curPage = page + 1;

    const fd = { ...filteres };
    fd['page'] = curPage;

    if (fd.type === "deposits") {
      const handler = await PaymentMethod.getByIdDeposits(fd);

      if (handler && handler.data) {
        if (fd.typeId === "pixtopay") {
          setItems(handler.data.rows);
        } else if (fd.typeId === "paybrokers" || fd.typeId === "pagfast") {
          setItems(handler.data.items);
        } else {
          setItems(handler.data);
        }
        setFilters(fd);
        setPage(curPage);
      }
    }

    if (fd.type === "withdrawals") {
      const handler = await PaymentMethod.getByIdWithDrawals(fd);

      if (handler && handler.data) {
        if (fd.typeId === "pixtopay") {
          setItems(handler.data.rows);
        } else if (fd.typeId === "paybrokers" || fd.typeId === "pagfast") {
          setItems(handler.data.items);
        } else {
          setItems(handler.data);
        }
        setFilters(fd);
        setPage(curPage);
      }
    }
  }

  const backPage = async () => {
    let curPage = page - 1;

    const fd = { ...filteres };
    fd['page'] = curPage;

    if (fd.type === "deposits") {
      const handler = await PaymentMethod.getByIdDeposits(fd);

      if (handler && handler.data) {
        if (fd.typeId === "pixtopay") {
          setItems(handler.data.rows);
        } else if (fd.typeId === "paybrokers") {
          setItems(handler.data.items);
        } else {
          setItems(handler.data);
        }
        setFilters(fd);
        setPage(curPage);
      }
    }

    if (fd.type === "withdrawals") {
      const handler = await PaymentMethod.getByIdWithDrawals(fd);

      if (handler && handler.data) {
        if (fd.typeId === "pixtopay") {
          setItems(handler.data.rows);
        } else if (fd.typeId === "paybrokers") {
          setItems(handler.data.items);
        } else {
          setItems(handler.data);
        }
        setFilters(fd);
        setPage(curPage);
      }
    }
  }

  // https://backoffice.pay2free.com/back-api/deposits?page=1&page_size=10&$gte=2024-08-26T03:00:00.000Z&$lte=2024-09-26T02:59:59.999Z&merchants[]=65663018747b4faf7ea05dd8&cpf=48075148819

  const handleFilter = async (data: any) => {

    setLoading(true);
    setPage(1);
    let dataAny: any = {};
    dataAny[data.search] = data[data.search];
    dataAny['typeId'] = data.paymentMethod;
    dataAny['page'] = 1;
    if (data['startDate']) {
      dataAny['startDate'] = data['startDate'];
    }
    if (data['endDate']) {
      dataAny['endDate'] = data['endDate'];
    }
    if (data.type) {
      dataAny['type'] = data.type;
    }
    if (data.status) {
      dataAny['status'] = data.status;
    }
    dataAny['limit'] = 10;

    setFilters(dataAny);

    if (data.type === "deposits") {
      const handler = await PaymentMethod.getByIdDeposits(dataAny);

      if (handler && handler.data) {
        if (data.paymentMethod === "pixtopay") {
          if (dataAny.transactionId) {
            let arrResponse = [];
            arrResponse.push(handler.data);
            setItems(arrResponse);
          } else {
            setItems(handler.data.rows);
          }
        } else if (data.paymentMethod === "paybrokers" || data.paymentMethod === "pagfast") {
          setItems(handler.data.items);
        } else if (data.paymentMethod === "paag") {
          if (dataAny.transactionId) {
            let arrResponse = [];
            arrResponse.push(handler.data);
            setItems(arrResponse);
          } else {
            setItems(handler.data);
          }
        } else {
          setItems(handler.data);
        }
      } else {
        setItems([]);
      }
    }

    if (data.type === "withdrawals") {
      const handler = await PaymentMethod.getByIdWithDrawals(dataAny);

      if (handler && handler.data) {
        if (data.paymentMethod === "pixtopay") {
          setItems(handler.data.rows);
        } else if (data.paymentMethod === "paybrokers" || data.paymentMethod === "pagfast") {
          setItems(handler.data.items);
        } else if (data.paymentMethod === "paag") {
          if (dataAny.transactionId) {
            let arrResponse = [];
            arrResponse.push(handler.data);
            setItems(arrResponse);
          } else {
            setItems(handler.data);
          }
        } else {
          setItems(handler.data);
        }
      } else {
        setItems([]);
      }
    }
    setLoading(false);
  }

  const handleClear = async () => {
    setItems([]);
  }

  const getFilterSearch = (data: any) => {
    if (data.payeeCpf) {
      return "payeeCpf";
    }
    if (data.payerCpf) {
      return "payerCpf";
    }
    if (data.transactionId) {
      return "transactionId";
    }

    return "payeeCpf";
  }

  return (
    <MainLayout>
      <Filter
        filters={[
          {
            name: "paymentMethod",
            placeholder: t("Pesquisar por") as string,
            type: FTypes.select,
            predefinedValue: "pixtopay",
            options: [
              {
                label: "PixToPay",
                value: "pixtopay",
              },
              {
                label: "PagFast",
                value: "pagfast",
              },
              {
                label: "Pay2Free",
                value: "pay2free",
              },
              {
                label: "Paag",
                value: "paag",
              },
              {
                label: "Paybrokers",
                value: "paybrokers",
              }
            ],
          },
          {
            name: "status",
            placeholder: t("Status") as string,
            type: FTypes.select,
            options: [
              {
                label: "PROCESSING",
                value: "PROCESSING",
              },
              {
                label: "WAITING",
                value: "WAITING",
              },
              {
                label: "PAID",
                value: "PAID",
              },
              {
                label: "CANCELED",
                value: "CANCELED",
              },
              {
                label: "REFUNDED",
                value: "REFUNDED",
              }
            ],
          },
          {
            name: "type",
            placeholder: t("Pesquisar por") as string,
            type: FTypes.select,
            predefinedValue: "deposits",
            options: [
              {
                label: "Deposits",
                value: "deposits",
              },
              {
                label: "Withdrawals",
                value: "withdrawals",
              }
            ],
          },
          {
            name: "search",
            placeholder: t("Pesquisar por") as string,
            type: FTypes.selectInput,
            predefinedValue: "payeeCpf",
            options: [
              {
                label: "CPF do Pagador",
                value: "payerCpf",
              },
              {
                label: "CPF do Cliente",
                value: "payeeCpf",
              },
              {
                label: "ID da Transação",
                value: "transactionId",
              },
              {
                label: "EndToEndId",
                value: "endToEndId",
              }
            ],
          },
          {
            name: "startDate,endDate",
            placeholder: t("Pesquisar por") as string,
            type: FTypes.range,
            predefinedValue: [startOfDay(new Date()), endOfDay(addDays(new Date(), +1))],
            format: 'YYYY-MM-DD'
          }
        ]}
        onPress={handleFilter}
        onClear={handleClear}
        loading={loading}
      />

      {items?.length === 0 && <TablePaginated
        totalItems={0}
        data={[]}
        keys={[
          {
            name: "id",
            type: "string"
          },
          {
            name: "cpf",
            type: "string"
          },
          {
            name: "paymentMethod",
            type: "string"
          },
          {
            name: "createdAt",
            type: "string"
          },
          {
            name: "value",
            type: "money"
          },
          {
            name: "status",
            type: "string"
          }
        ]}
        perPage={10}
        onPageChange={function (page: number): void {
          throw new Error("Function not implemented.");
        }}
      />}

      {!loading && items && items.length > 0 && filteres && <TablePaginated
        totalItems={0}
        data={items}
        keys={getKeysFromTables(filteres.typeId, filteres.type, getFilterSearch(filteres))}
        perPage={10}
        onPageChange={function (page: number): void {
          throw new Error("Function not implemented.");
        }}
      />}

      {!loading && items && items.length > 0 && <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}>
        {page !== 1 && <Button variant='soft' onClick={() => {
          backPage();
        }}>{t('Anterior')}</Button>}
        <div>{page}</div>
        <Button variant='soft' onClick={() => {
          nextPage();
        }}>{t('Próximo')}</Button>
      </Flex>}

    </MainLayout>
  );
}

export default PaymentMethods;