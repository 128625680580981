import { Button, Dialog, Flex, TextField, Text, Spinner } from '@radix-ui/themes';
import { BookmarkIcon } from 'lucide-react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Bonus from 'src/infraestructure/services/api/Bonus';

type TBonusCancelComp = {
    bonusId: string;
    userId: string;
}

type TBonusCancelForm = {
    notes: string;
}

const CancelBonus = ({ bonusId, userId }: TBonusCancelComp) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TBonusCancelForm>()
    const onSubmit: SubmitHandler<TBonusCancelForm> = async (data) => {
        setLoading(true);
        const request = await Bonus.cancel({
            bonusId: bonusId,
            clientId: userId,
            notes: data.notes
        });
        setLoading(false);
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <Button color="red" style={{ fontSize: 12 }}>Cancelar</Button>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
                <Dialog.Title>Motivo de Cancelamento</Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction="column" gap="3">
                        <label>
                            <TextField.Root
                                defaultValue=""
                                {...register('notes', {
                                    required: true
                                })}
                                placeholder="Notas"
                            />
                        </label>
                    </Flex>

                    <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                            <Button variant="soft" color="gray">
                                Voltar
                            </Button>
                        </Dialog.Close>
                        <Button type='submit' disabled={loading} color="red">
                            {loading && <Spinner loading>
                                <BookmarkIcon />
                            </Spinner>}
                            Cancelar
                        </Button>
                    </Flex>
                </form>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default CancelBonus;