import MainLayout from 'src/components/MainLayout';

const Deposits = () => {
    return (
        <MainLayout>
            <div></div>
        </MainLayout>
    );
}

export default Deposits;