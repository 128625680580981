
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en/translations.json";
import pt from "./pt/translations.json";

export const resources = {
  en: {
    translation: en,
    label: "English",
  },
  pt: {
    translation: pt,
    label: "Português",
  }
};

export const DEFAULT_LANGUAGE = localStorage.getItem("@App:language") ? localStorage.getItem("@App:language") as string : "pt";

i18next.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
});