import { createUseStyles } from "react-jss";

const useTablePaginatedStyles = createUseStyles({
    PaginateContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        gap: 10,
    },
    PageItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        cursor: 'pointer',
        paddingRight: 10,
        borderRadius: 3,
        fontSize: 13,
        "&.active": {
            backgroundColor: 'var(--accent-9)'
        }
    }
});

export default useTablePaginatedStyles;