import { TBetSelectionsType } from "src/@types/reports";
import GenericDAO from "../GenericDAO";

const routeSelections = "/v1/clients/bet-selections";

const getBetSelections = async (data: TBetSelectionsType) => GenericDAO.get(routeSelections, undefined, [
    {
        name: "betId",
        value: data.betId as any,
    },
    {
        name: "type",
        value: data.type as any,
    }
], );

export default {
    getBetSelections
};