import { Badge, Button, Flex, Table } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TAffiliatesWithdrawalsResponse } from 'src/@types/affiliates';
import Affiliates from 'src/infraestructure/services/api/Affiliates';
import { getColorFromStatus } from 'src/utils';

export type TAffProps = {
    id: string;
}

const AffiliatesWithdrawals = ({ id }: TAffProps) => {

    const [list, setList] = useState<TAffiliatesWithdrawalsResponse[]>();
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const request = await Affiliates.getWithdrawals({
            affiliateId: id,
            page: 1,
            perPage: 10
        });

        if (request && request.data) {
            setList(request.data);
        }
    }

    const backPage = async () => {
        let curPage = page - 1;
        const request = await Affiliates.getWithdrawals({
            affiliateId: id,
            page: curPage,
            perPage: 10
        });

        if (request && request.data) {
            setList(request.data);
        }
        setPage(curPage);
    }

    const nextPage = async () => {
        let curPage = page + 1;
        const request = await Affiliates.getWithdrawals({
            affiliateId: id,
            page: curPage,
            perPage: 10
        });

        if (request && request.data) {
            setList(request.data);
        }
        setPage(curPage);
    }


    return (
        <>
            <Table.Root variant='surface'>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>{t('Withdrawal_Id')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Username')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Amount')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('PaymentMethod')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Data do Pedido')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Data Processada')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Status')}</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>


                <Table.Body>
                    {list && list.length > 0 && list.map((i, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.RowHeaderCell>{i.withdrawalId}</Table.RowHeaderCell>
                                <Table.Cell>{i.username}</Table.Cell>
                                <Table.Cell>{i.amount}</Table.Cell>
                                <Table.Cell>{i.paymentMethod}</Table.Cell>
                                <Table.Cell>{i.dateSent}</Table.Cell>
                                <Table.Cell>{i.processDate}</Table.Cell>
                                <Table.Cell>
                                    <Badge color={getColorFromStatus(t(i.status))}>
                                        {t(i.status)}
                                    </Badge>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })
                    }
                </Table.Body>
            </Table.Root>
            <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}>
                {page !== 1 && <Button variant='soft' onClick={() => {
                    backPage();
                }}>Anterior</Button>}
                <div>{page}</div>
                <Button variant='soft' onClick={() => {
                    nextPage();
                }}>Próximo</Button>
            </Flex>
        </>
    );
}

export default AffiliatesWithdrawals;