import { Badge, Button, Dialog, Flex, Grid, IconButton, Spinner, Tooltip } from "@radix-ui/themes";
import { EyeIcon } from "lucide-react";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentMethod from "src/infraestructure/services/api/PaymentMethod";
import useModalReceiptStyles from "src/styles/components/modalReceiptStyles";

export type TModalReceiptProps = {
    type: string;
    method: string;
    transactionId: string;
    selectedId: string;
    children: () => ReactNode;
}

const ModalReceipt = ({ type, method, transactionId, children, selectedId }: TModalReceiptProps) => {

    const { t } = useTranslation();
    const { TextTitle, TextInfo } = useModalReceiptStyles();
    const [transactionInfo, setTransactionInfo] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (selectedId === transactionId) {
            fetchInfo();
        }
    }, [selectedId]);

    const fetchInfo = async () => {
        setLoading(true);
        if (type !== "withdrawals") {
            const handler = await PaymentMethod.getByIdDeposits({
                transactionId: transactionId,
                typeId: method
            });

            console.log(handler);
            if (handler && handler.data) {
                setTransactionInfo(handler.data);
            }
        } else {
            const handler = await PaymentMethod.getByIdWithDrawals({
                transactionId: transactionId,
                typeId: method
            });

            console.log(handler);
            if (handler && handler.data) {
                setTransactionInfo(handler.data);
            }
        }

        setLoading(false);
    }

    return (
        <Dialog.Root>
            <Tooltip content="Comprovante">
                <Dialog.Trigger>
                    {children()}
                </Dialog.Trigger>
            </Tooltip>

            <Dialog.Content maxWidth="500px">
                <Dialog.Title>{t(`View_${type === "deposits" ? 'Deposit' : 'Withdraw'}`)}</Dialog.Title>
                {/* <Dialog.Description size="2" mb="4">
                    Make changes to your profile.
                </Dialog.Description> */}

                {loading &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                        <Spinner size={"3"} />
                    </div>
                }

                {method === "pixtopay" && type === "deposits" && !loading && <Flex direction="column">
                    <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>{t(`Data_${type === "deposits" ? 'Deposit' : 'Withdraw'}`)}</div>
                    <hr style={{ width: '100%', opacity: .1 }} />
                    <Flex direction={"column"}>
                        <div className={TextTitle}>{t('Name')}:</div>
                        <div className={TextInfo}>{transactionInfo.nome}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Document')}:</div>
                        <div className={TextInfo}>{transactionInfo.cpf}</div>
                    </Flex>
                    <Grid style={{ marginTop: 20 }} columns={"2"}>
                        <Flex direction={"column"}>
                            <div className={TextTitle}>{t('Created_Date')}:</div>
                            <div className={TextInfo}>{transactionInfo.data}</div>
                        </Flex>

                        <Flex direction={"column"}>
                            <div className={TextTitle}>{t('Payment_Date')}:</div>
                            <div className={TextInfo}>{moment(transactionInfo.pix_paid_at).format("DD/MM/YYYY HH:mm:ss")}</div>
                        </Flex>
                    </Grid>

                    <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>{t(`Payer_Data`)}</div>
                    <hr style={{ width: '100%', opacity: .1 }} />
                    <Flex direction={"column"}>
                        <div className={TextTitle}>{t('Payer_Name')}:</div>
                        <div className={TextInfo}>{transactionInfo.pix_payer_name}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Payer_Document')}:</div>
                        <div className={TextInfo}>{transactionInfo.pix_payer_document_number}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Email')}:</div>
                        <div className={TextInfo}>{transactionInfo.email}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Telefone')}:</div>
                        <div className={TextInfo}>{transactionInfo.phone_number}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('E2E ID')}:</div>
                        <div className={TextInfo}>{transactionInfo.pix_e2e_id}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Payer_Bank')}:</div>
                        <div className={TextInfo}>{transactionInfo.pix_payer_bank_name}</div>
                    </Flex>
                </Flex>}

                {method === "pay2free" && type === "deposits" && !loading && <Flex direction="column">
                    <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>{t(`Data_${type === "deposits" ? 'Deposit' : 'Withdraw'}`)}</div>
                    <hr style={{ width: '100%', opacity: .1 }} />
                    <Flex direction={"column"}>
                        <div className={TextTitle}>{t('TxID')}:</div>
                        <div className={TextInfo}>{transactionInfo.txid}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Bank')}:</div>
                        <div className={TextInfo}>{transactionInfo.bankName}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Name')}:</div>
                        <div className={TextInfo}>{transactionInfo.debtor.name}</div>

                        <Grid style={{ marginTop: 20 }} columns={"2"}>
                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Document')}:</div>
                                <div className={TextInfo}>{transactionInfo.debtor.cpf}</div>
                            </Flex>

                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Amount')}:</div>
                                <div className={TextInfo}>R${transactionInfo.value}</div>
                            </Flex>
                        </Grid>

                    </Flex>
                    <Grid style={{ marginTop: 20 }} columns={"2"}>
                        <Flex direction={"column"}>
                            <div className={TextTitle}>{t('Created_Date')}:</div>
                            <div className={TextInfo}>{moment(transactionInfo.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                        </Flex>

                        <Flex direction={"column"}>
                            <div className={TextTitle}>{t('Payment_Date')}:</div>
                            <div className={TextInfo}>{moment(transactionInfo.payedAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                        </Flex>
                    </Grid>

                    {transactionInfo.status === "completed_reversal" && <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>{t(`Refunded_Data`)}</div>}
                    {transactionInfo.status === "completed_reversal" && <hr style={{ width: '100%', opacity: .1 }} />}

                    {transactionInfo.status === "completed_reversal" && <Grid style={{ marginTop: 10 }} columns={"2"}>
                        <Flex direction={"column"}>
                                <div className={TextTitle}>{t('RefundedDate')}:</div>
                                <div className={TextInfo}>{moment(transactionInfo.reversal.reversedAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                            </Flex>

                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Refunded_Amout')}:</div>
                                <div className={TextInfo}>R$ {transactionInfo.reversal.amount}</div>
                            </Flex>
                    </Grid>}

                    {transactionInfo.status === "completed_reversal" && <Flex direction={"column"} mt={"20px"}>
                            <div className={TextTitle}>{t('Refund_Reason')}:</div>
                            <div className={TextInfo}>{transactionInfo.reversal.reason}</div>
                        </Flex>}

                    {transactionInfo.status === "completed_reversal" && <Flex direction={"column"} mt={"20px"}>
                        <div className={TextTitle}>{t('EndToEndId')}:</div>
                        <div className={TextInfo}>{transactionInfo.reversal.e2eid}</div>
                    </Flex>}

                    {transactionInfo.payer && <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>{t(`Payer_Data`)}</div>}
                    {transactionInfo.payer && <hr style={{ width: '100%', opacity: .1 }} />}
                    {transactionInfo.payer && <Flex direction={"column"}>
                        <div className={TextTitle}>{t('Payer_Name')}:</div>
                        <div className={TextInfo}>{transactionInfo.payer.name}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Payer_Document')}:</div>
                        <div className={TextInfo}>{transactionInfo.payer.document}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Email')}:</div>
                        <div className={TextInfo}>{transactionInfo.debtor.email}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('E2E ID')}:</div>
                        <div className={TextInfo}>{transactionInfo.e2eid}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Payer_Bank')}:</div>
                        <div className={TextInfo}>{transactionInfo.payer.bank_account.bank_name}</div>

                        <Grid style={{ marginTop: 20 }} columns={"2"}>
                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Account_Agency')}:</div>
                                <div className={TextInfo}>{transactionInfo.payer.bank_account.accountBranch}</div>
                            </Flex>

                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Account_Number')}:</div>
                                <div className={TextInfo}>{transactionInfo.payer.bank_account.accountNumber}</div>
                            </Flex>
                        </Grid>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Account_Type')}:</div>
                        <div className={TextInfo}>{transactionInfo.payer.bank_account.accountType === "CACC" ? "Conta Corrente" : "Conta Poupança"}</div>
                    </Flex>}
                </Flex>}

                {method === "pay2free" && type === "withdrawals" && !loading && <Flex direction="column">
                    <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>{t(`Data_Withdraw`)}</div>
                    <hr style={{ width: '100%', opacity: .1 }} />
                    <Flex direction={"column"}>
                        <div className={TextTitle}>{t('TxID')}:</div>
                        <div className={TextInfo}>{transactionInfo.transactionId ?? ""}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Bank')}:</div>
                        <div className={TextInfo}>{transactionInfo.bankName}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Name')}:</div>
                        <div className={TextInfo}>{transactionInfo.debtor.name}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Email')}:</div>
                        <div className={TextInfo}>{transactionInfo.debtor.email}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Reject_Reason')}:</div>
                        <div className={TextInfo}><Badge color="red" style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>{transactionInfo.error}</Badge></div>

                        <Grid style={{ marginTop: 20 }} columns={"2"}>
                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Document')}:</div>
                                <div className={TextInfo}>{transactionInfo.debtor.cpf}</div>
                            </Flex>

                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Amount')}:</div>
                                <div className={TextInfo}>R${transactionInfo.value}</div>
                            </Flex>
                        </Grid>

                    </Flex>
                    <Grid style={{ marginTop: 20 }} columns={"2"}>
                        <Flex direction={"column"}>
                            <div className={TextTitle}>{t('Created_Date')}:</div>
                            <div className={TextInfo}>{moment(transactionInfo.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                        </Flex>

                        <Flex direction={"column"}>
                            <div className={TextTitle}>{t('Payment_Date')}:</div>
                            <div className={TextInfo}>{moment(transactionInfo.payedAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                        </Flex>
                    </Grid>

                    {transactionInfo.payer && !loading && <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>{t(`Payer_Data`)}</div>}
                    {transactionInfo.payer && !loading && <hr style={{ width: '100%', opacity: .1 }} />}
                    {transactionInfo.payer && !loading && <Flex direction={"column"}>
                        <div className={TextTitle}>{t('Payer_Name')}:</div>
                        <div className={TextInfo}>{transactionInfo.payee.name}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Payer_Document')}:</div>
                        <div className={TextInfo}>{transactionInfo.payee.document}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('E2E ID')}:</div>
                        <div className={TextInfo}>{transactionInfo.e2eid}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Payer_Bank')}:</div>
                        <div className={TextInfo}>{transactionInfo.payee.bank_account.bank_name}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Pix_Key_Type')}:</div>
                        <div className={TextInfo}>{transactionInfo.payee.pixKeyType}</div>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Pix_Key')}:</div>
                        <div className={TextInfo}>{transactionInfo.payee.pixKey}</div>

                        <Grid style={{ marginTop: 20 }} columns={"2"}>
                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Account_Agency')}:</div>
                                <div className={TextInfo}>{transactionInfo.payee.bank_account.accountBranch}</div>
                            </Flex>

                            <Flex direction={"column"}>
                                <div className={TextTitle}>{t('Account_Number')}:</div>
                                <div className={TextInfo}>{transactionInfo.payee.bank_account.accountNumber}</div>
                            </Flex>
                        </Grid>

                        <div className={TextTitle} style={{ marginTop: 20 }}>{t('Account_Type')}:</div>
                        <div className={TextInfo}>{transactionInfo.payee.bank_account.accountType === "CACC" ? "Conta Corrente" : "Conta Poupança"}</div>
                    </Flex>}
                </Flex>}

                <Flex gap="3" mt="4" justify="end">
                    <Dialog.Close>
                        <Button variant="soft" color="gray">
                            {t('Close')}
                        </Button>
                    </Dialog.Close>
                    {/* <Dialog.Close>
                        <Button>Save</Button>
                    </Dialog.Close> */}
                </Flex>
            </Dialog.Content>
        </Dialog.Root>

    );
}

export default ModalReceipt;