import { Button, Dialog, Flex, IconButton, Spinner, Tooltip } from '@radix-ui/themes';
import { BookmarkIcon, Lock } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type TResetProps = {
    loading: boolean;
    onFinish: () => void;
}

const RemoveExclusion = ({ loading, onFinish }: TResetProps) => {

    return (
        <Dialog.Root>
            <Tooltip content={"Remover AutoExlusão"}>
                <Dialog.Trigger>
                    <IconButton radius='full' color='red'>
                        <Lock size={19} />
                    </IconButton>
                </Dialog.Trigger>
            </Tooltip>

            <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
                <Dialog.Title>Tem certeza que deseja remover a autoexclusão?</Dialog.Title>
                <Flex gap="3" mt="4" justify="end">
                    <Dialog.Close>
                        <Button variant="soft" color="gray">
                            Voltar
                        </Button>
                    </Dialog.Close>
                    <Dialog.Close>
                        <Button onClick={onFinish} type='submit' disabled={loading} color="red">
                            {loading && <Spinner loading>
                                <BookmarkIcon />
                            </Spinner>}
                            Sim
                        </Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default RemoveExclusion;