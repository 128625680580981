
import { Button, Dialog, Flex } from "@radix-ui/themes";
import { useTranslation } from "react-i18next";
import userProfileStyles from "src/styles/components/userProfileStyles";

export type TViewMessage = {
    text: string;
    description: string;
}

const ViewMessage = ({ text, description }: TViewMessage) => {

    const { Hlink } = userProfileStyles();
    const { t } = useTranslation();
    
    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <div className={Hlink}>Ver Mensagem</div>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px">
                <Dialog.Title>{description}</Dialog.Title>
            
                <Flex direction="column" gap="3">
                     <div dangerouslySetInnerHTML={{ __html: text }} />
                </Flex>

                <Flex gap="3" mt="4" justify="end">
                    <Dialog.Close>
                        <Button variant="classic" color="gray">
                            {t('Close')}
                        </Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default ViewMessage;