import { TRestrictionsRequest } from "src/@types/restrictions";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/restrictions";
const removeRestrictionsRoute = "/v1/clients/:id/remove-restrictions";

const getById = async (data: TRestrictionsRequest) => GenericDAO.replaceGetById(route, data.clientId, true);

const save = async (data: TRestrictionsRequest) => GenericDAO.putWithId(removeRestrictionsRoute, data, undefined, true);

export default {
    getById,
    save
};