import { IconButton, Spinner, Tooltip } from '@radix-ui/themes';
import { RefreshCcw } from 'lucide-react';
import { useEffect, useState } from 'react';
import Status from 'src/infraestructure/services/api/Status';

type TClientStatusProps = {
    onFinish: (result: string) => void;
    userId: string;
}

const ClientStatus = ({ onFinish, userId }: TClientStatusProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    
    const handleUpdateStatus = async () => {
        setLoading(true);
        const getStatus = await Status.getStatus({
            clientId: userId
        })

        if(getStatus && getStatus.data) {
            onFinish(getStatus.data.status);
        }
        setLoading(false);
    }
    
    useEffect(() => {
        handleUpdateStatus();
    }, [])

    return (
        <div>
            <Tooltip content={"Atualizar Status"}>
                <IconButton size={"1"} radius='full' onClick={() => handleUpdateStatus()}>
                    {!loading && <RefreshCcw size={14} />}
                    {loading && <Spinner />}
                </IconButton>
            </Tooltip>
        </div>
    );
}

export default ClientStatus;