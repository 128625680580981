import { Flex } from '@radix-ui/themes';
import { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';

type MainLayouProps = {
  children: ReactNode;
}

const MainLayout = ({ children }: MainLayouProps) => {
  return(
    <Flex>
      <Header />
      <div style={{ marginTop: 40, padding: 60, width: '100%' }}>
        {children}
      </div>
      <Footer />
    </Flex>
  );
}

export default MainLayout;