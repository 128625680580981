import { AlertDialog, Badge, Button, Flex, IconButton, Table } from "@radix-ui/themes";
import { EditIcon, KeyIcon, KeyRoundIcon, PlusIcon, TrashIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { TUserInfoResponse } from "src/@types/user";
import MainLayout from "src/components/MainLayout";
import TablePaginated from "src/components/TablePaginated";
import AdminUsersList from 'src/infraestructure/services/api/Admin/Users';

const AdminUsers = () => {
    const [usersList, setUsersList] = useState<TUserInfoResponse[]>();
    const [totalItems, setTotalItems] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        fetchAdminUsers();
    }, []);

    const fetchAdminUsers = async () => {
        const requestUsers = await AdminUsersList.get({
            page: page,
            limit: 20
        });

        if (requestUsers.statusCode === 200) {
            setTotalItems(requestUsers.pagination.totalUsers);
            setUsersList(requestUsers.data as TUserInfoResponse[]);
        }
    }

    const deleteUser = async (userId: string) => {
        const remove = await AdminUsersList.remove(userId);
        if(remove.statusCode === 200 || remove.statusCode === 201) {
            fetchAdminUsers();
        }
    }
    
    console.log(totalItems);

    return (
        <MainLayout>
            <Button onClick={() => {
                navigate('/admin/create');
            }}><PlusIcon /> {t('Criar Usuário')}</Button>
            <TablePaginated 
                totalItems={totalItems} 
                keys={
                    [
                        {
                            name: "avatarUrl",
                            type: "image",
                            imageSize: 50
                        },
                        {
                            name: "userId",
                            type: "string"
                        },
                        {
                            name: "name",
                            type: "string"
                        },
                        {
                            name: "role",
                            type: "tag",
                            children: ((response) => {
                                return (
                                    <Badge color={"blue"}>{response.role}</Badge>
                                )
                            })
                        },
                        {
                            name: "blocked",
                            type: "tag",
                            children: ((response) => {
                                return (
                                    <Badge color={response.blocked ? "green" : "red"}>{response.blocked ? "Sim" : "Não"}</Badge>
                                )
                            })
                        },
                        {
                            name: "actions",
                            type: "string",
                            children: ((response) => {
                                return(
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                        <IconButton
                                            size="1"
                                            aria-label="Copy value"
                                            color="gray"
                                            onClick={() => {
                                                navigate(`/admin/edit/${response.userId}`);
                                            }}
                                            variant="ghost"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <AlertDialog.Root>
                                            <AlertDialog.Trigger>
                                                <IconButton
                                                    size="1"
                                                    aria-label="Copy value"
                                                    color="gray"
                                                    variant="ghost"
                                                >
                                                    <TrashIcon />
                                                </IconButton>
                                            </AlertDialog.Trigger>
                                            <AlertDialog.Content maxWidth="450px">
                                                <AlertDialog.Title>Deletar Usuário</AlertDialog.Title>
                                                <AlertDialog.Description size="2">
                                                    Tem certeza que deseja deletar o usuário?
                                                </AlertDialog.Description>

                                                <Flex gap="3" mt="4" justify="end">
                                                    <AlertDialog.Cancel>
                                                        <Button variant="soft" color="gray">
                                                            Cancelar
                                                        </Button>
                                                    </AlertDialog.Cancel>
                                                    <AlertDialog.Action>
                                                        <Button onClick={() => {
                                                            deleteUser(response.userId);
                                                        }} variant="solid" color="red">
                                                            Deletar
                                                        </Button>
                                                    </AlertDialog.Action>
                                                </Flex>
                                            </AlertDialog.Content>
                                        </AlertDialog.Root>
                                    </div>
                                )
                            })
                        }
                    ]
                }
                data={usersList}
                perPage={20} 
                onPageChange={async function (page: number) {
                    setPage(page);
                    const requestUsers = await AdminUsersList.get({
                        page: page,
                        limit: 20
                    });
            
                    if (requestUsers.statusCode === 200) {
                        setTotalItems(requestUsers.pagination.totalUsers);
                        setUsersList(requestUsers.data as TUserInfoResponse[]);
                    }
                }}                
            />

        </MainLayout>
    );
}

export default AdminUsers;