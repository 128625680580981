import { TDataPut } from "src/@types/admin";
import GenericDAO from "../../GenericDAO";

const route = "/v1/admin/config/payments";
const routePut = "/v1/admin/config/payments/edit";

const get = async () => GenericDAO.get(route, null, undefined, true);
const put = async (data: TDataPut) => GenericDAO.savePut(routePut, data, true);

export default {
    get,
    put
};