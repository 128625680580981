import { Box, Button, Flex, Select, Spinner, Text, TextField } from "@radix-ui/themes";
import useEventListener from "@use-it/event-listener";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { BookmarkIcon } from "lucide-react";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "rsuite";

export type Options = {
    label: string;
    value: string;
}

interface TFilterItem {
    name: string;
    type: FTypes;
    value?: string;
    placeholder?: string;
    options?: Options[];
    defaultValue?: any;
    format?: string;
    predefinedValue?: string | [Date, Date];
}

interface TFilters {
    filters: TFilterItem[];
    loading?: boolean;
    onPress: (e: any) => void;
    onClear?: () => void;
    showTitle?: boolean;
}

export enum FTypes {
    input = "input",
    date = "date",
    range = "range",
    select = "select",
    selectInput = "select-input",
    checkbox = "checkbox",
}

const Filter = ({
    filters,
    onPress,
    loading,
    onClear,
    showTitle = true
}: TFilters) => {

    const { t } = useTranslation();

    const [selectValue, setSelectValue] = useState<string>();
    const [inputValue, setInputValue] = useState<string>("");
    const [selectInput, setSelectInput] = useState<Options>();
    const [selectDate, setSelectDate] = useState<Date[]>();
    const [preLoaded, setPreLoader] = useState<boolean>(false);
    const [selectOnly, setSelectOnly] = useState<any>();

    const [inputHandler, setInputHandler] = useState<any>();

    const handleChangeSelectInput = async (e: string, name: string) => {
        // setInputValue("");
        setInputValue("");
        setSelectValue(e);
        const curValues: any = { ...inputHandler };
        curValues[name] = e;
        setInputHandler(curValues);
    };

    const handleChangeSelectOnly = async (name: string, e: string) => {
        // setInputValue("");
        // setSelectOnly(e);
        const selectOnlyCurrent = { ...selectOnly };
        selectOnlyCurrent[name] = e;

        setSelectOnly(selectOnlyCurrent);

        const curValues: any = { ...inputHandler };
        curValues[name] = e;

        setInputHandler(curValues)
    };

    const handleChangeInput = (e: any) => {
        setInputValue(e.target.value);
        const curValues: any = { ...inputHandler };
        if (selectValue) {
            curValues[selectValue] = (selectValue === "personalId" || selectValue === "payeeCpf" || selectValue === "payerCpf") ? e.target.value.replaceAll(".", "").replace("-", "").trim() : e.target.value.trim();
        }
        setInputHandler(curValues);
    }

    const handleChangeSelectDP = (e: any) => {
        console.log(selectDate);
        if (selectDate?.length) {
            let element: HTMLElement = document.getElementsByClassName(
                "rs-btn rs-btn-primary rs-btn-sm"
            )[0] as HTMLElement;
            setTimeout(() => {
                element.click();
            }, 200);
        } else {
            setSelectDate([e]);
        }
    }

    const handleDateRange = (name: string, e: any, format?: string) => {
        let curValues: any = { ...inputHandler };
        let nameSplit = name.split(",");
        if (e && e.length > 0) {
            if (format) {
                curValues[nameSplit[0]] = moment(e[0]).format(format);
                curValues[nameSplit[1]] = moment(e[1]).format(format);
            } else {
                curValues[nameSplit[0]] = e[0];
                curValues[nameSplit[1]] = e[1];
            }

            setInputHandler(curValues);
        } else {
            let curValues: any = { ...inputHandler };
            let nameSplit = name.split(",");
            delete curValues[nameSplit[0]]
            delete curValues[nameSplit[1]]
            setInputHandler(curValues);
        }
    }

    useEffect(() => {
        let curValues: any = { ...inputHandler };
        let selectOnlyCurrent: any = { ...selectOnly };
        filters.map((i) => {
            if (i.predefinedValue) {

                if (i.type === FTypes.select) {
                    selectOnlyCurrent[i.name] = i.predefinedValue;
                }

                if (i.type === FTypes.selectInput) {
                    if(typeof i.predefinedValue === "string") {
                        setSelectValue(i.predefinedValue);   
                    }
                }

                if (i.type === FTypes.range) {
                    if(typeof i.predefinedValue !== "string") {
                        let splittedName = i.name.split(",");
                        curValues[splittedName[0]] = moment(i.predefinedValue[0]).format(i.format);
                        curValues[splittedName[1]] = moment(i.predefinedValue[1]).format(i.format);
                    }
                }
                
                if(i.name !== "startDate,endDate") {
                    curValues[i.name] = i.predefinedValue;
                }
            }
        })
        setSelectOnly(selectOnlyCurrent);
        setPreLoader(true);
        setInputHandler(curValues);
    }, []);

    function handler({ key }: any) {
        if (key === "Enter" && !window.location.pathname.includes("home")) {
            if (inputHandler && Object.keys(inputHandler).length > 0) {
                if (!loading) {
                    onPress(inputHandler);
                }
            } else {
                toast.error(`Selecione pelo menos 1 filtro.`);
            }
        }
    }

    useEventListener("keydown", handler);

    return (
        <Flex direction={"column"} gap={"30px"} width={"100%"}>
            {showTitle && <Text size={"5"}>{t('Filtros')}</Text>}
            <Flex direction={"row"} gap={"8px"} align={"center"}>
                {filters && filters.map((i, index) => {
                    return (
                        <div key={index}>
                            {i.type === FTypes.select && <Flex key={index} gap={"8px"}>
                                <Flex direction="column" maxWidth="160px">
                                    <Select.Root value={preLoaded ? selectOnly[i.name] : ""} onValueChange={(e) => handleChangeSelectOnly(i.name, e)}>
                                        <Select.Trigger />
                                        <Select.Content>
                                            <Select.Group>
                                                <Select.Label>{t('Selecione o Filtro')}</Select.Label>
                                                {i.options?.map((s, index) => {
                                                    return (
                                                        <Select.Item key={index} value={s.value}>{t(s.label)}</Select.Item>
                                                    )
                                                })}
                                            </Select.Group>
                                        </Select.Content>
                                    </Select.Root>
                                </Flex>
                            </Flex>

                            }
                            {i.type === FTypes.range &&
                                <Flex key={index} gap={'8px'}>
                                    <DateRangePicker
                                         defaultValue={typeof i.predefinedValue !== "string" ? i.predefinedValue : null}
                                         ranges={[
                                        {
                                            label: t("Hoje"),
                                            value: [startOfDay(new Date()), endOfDay(addDays(new Date(), +1))],
                                        },
                                        {
                                            label: t("Ontem"),
                                            value: [
                                                startOfDay(addDays(new Date(), -1)),
                                                endOfDay(addDays(new Date(), +1)),
                                            ],
                                        },
                                        {
                                            label: t("Últimos 7 Dias"),
                                            value: [
                                                startOfDay(subDays(new Date(), 7)),
                                                endOfDay(addDays(new Date(), +1)),
                                            ],
                                        },
                                        {
                                            label: t("Últimos 28 Dias"),
                                            value: [
                                                startOfDay(subDays(new Date(), 27)),
                                                endOfDay(addDays(new Date(), +1)),
                                            ],
                                        },
                                    ]}
                                        onClean={() => {
                                            setSelectDate([]);
                                        }}
                                        onClose={() => {
                                            setSelectDate([]);
                                        }}
                                        onExit={() => {
                                            setSelectDate([]);
                                        }}
                                        onExited={() => {
                                            setSelectDate([]);
                                        }}
                                        onExiting={() => {
                                            setSelectDate([]);
                                        }}
                                        placeholder="Selecione a Data"
                                        onChange={(e: any) => handleDateRange(i.name, e, i.format)}
                                        onSelect={(e: any) => handleChangeSelectDP(e)}
                                    // onOk={(e, index) => handleDateRange(i.name, e, i.format)}
                                    />
                                </Flex>
                            }
                            {i.type === FTypes.selectInput && <Flex key={index} gap={"8px"}>
                                <Flex direction="column" maxWidth="160px">
                                    <Select.Root value={selectValue} onValueChange={(e) => handleChangeSelectInput(e, i.name)}>
                                        <Select.Trigger />
                                        <Select.Content>
                                            <Select.Group>
                                                <Select.Label>{t('Selecione o Filtro')}</Select.Label>
                                                {i.options?.map((s, index) => {
                                                    return (
                                                        <Select.Item key={index} value={s.value}>{t(s.label)}</Select.Item>
                                                    )
                                                })}
                                            </Select.Group>
                                        </Select.Content>
                                    </Select.Root>
                                </Flex>
                                <Box minWidth="300px">
                                    <TextField.Root value={inputValue} size="2" placeholder="Pesquisar…" onChange={(e) => handleChangeInput(e)} />
                                </Box>
                            </Flex>}
                        </div>
                    )
                })}
                {!loading && <Button onClick={() => {
                    if (inputHandler && Object.keys(inputHandler).length > 0) {
                        if (!loading) {
                            onPress(inputHandler);
                        }
                    } else {
                        toast.error(`Selecione pelo menos 1 filtro.`);
                    }
                }} onKeyDown={(e: any) => {
                    if (inputHandler && Object.keys(inputHandler).length > 0) {
                        if (!loading) {
                            onPress(inputHandler);
                        }
                    } else {
                        toast.error(`Selecione pelo menos 1 filtro.`);
                    }
                }} variant="classic">{t('Pesquisar')}</Button>}
                {loading && <Button disabled variant="classic">
                    <Spinner loading>
                        <BookmarkIcon />
                    </Spinner>
                    {t('Pesquisar')}
                </Button>}
                {/* <Button variant="soft" onClick={() => {
                    setInputValue("");
                    setInputHandler({});
                    setSelectDate([]);
                    setSelectOnly({});
                    if (onClear) {
                        onClear!();
                    }
                }}>
                    {t('Clear')}
                </Button> */}
            </Flex>
        </Flex>
    );
}

export default Filter;