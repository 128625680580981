import { TDeposits, TReceiptRequest } from "src/@types/payments";
import GenericDAO from "../GenericDAO";

const routeDeposits = "/v1/payments/:id/deposits";
const routeWithdrawals = "/v1/payments/:id/withdrawals";
const routeReceipt = "/v1/payments/:id/receipt";

const getByIdDeposits = async (data: TDeposits) => GenericDAO.getByIdWithParamsType(routeDeposits, data.typeId, undefined, [
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "transactionId",
        value: data.transactionId as any,
    },
    {
        name: "payeeCpf",
        value: data.payeeCpf as any,
    },
    {
        name: "payerCpf",
        value: data.payerCpf as any,
    },
    {
        name: "endToEndId",
        value: data.endToEndId as any,
    },
    {
        name: "status",
        value: data.status as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "limit",
        value: data.limit as any,
    }
], true);

const getByIdWithDrawals = async (data: TDeposits) => GenericDAO.getByIdWithParamsType(routeWithdrawals, data.typeId, undefined, [
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "transactionId",
        value: data.transactionId as any,
    },
    {
        name: "payeeCpf",
        value: data.payeeCpf as any,
    },
    {
        name: "payerCpf",
        value: data.payerCpf as any,
    },
    {
        name: "endToEndId",
        value: data.endToEndId as any,
    },
    {
        name: "status",
        value: data.status as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "limit",
        value: data.limit as any,
    }
], true);

const getReceiptById = async (data: TReceiptRequest) => GenericDAO.getByIdWithParamsType(routeReceipt, data.typeId, undefined, [
    {
        name: "transactionId",
        value: data.transactionId as any,
    },
    {
        name: "id",
        value: data.id as any,
    },
    {
        name: "status",
        value: data.status as any,
    },
    {
        name: "endToEndId",
        value: data.endToEndId as any,
    },
    {
        name: "type",
        value: data.type as any,
    }
], true);

export default {
    getByIdDeposits,
    getByIdWithDrawals,
    getReceiptById
};