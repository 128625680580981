import { Badge, IconButton, Spinner, Table, Tooltip } from '@radix-ui/themes';
import { CheckIcon, X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TAffiliatesTransactionsResponse } from 'src/@types/affiliates';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import Affiliates from 'src/infraestructure/services/api/Affiliates';
import useTablePaginatedStyles from 'src/styles/components/tablePaginatedStyles';
import { getColorFromStatus } from 'src/utils';

const AffiliatesTransactions = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { PaginateContainer, PageItem } = useTablePaginatedStyles();
    const [loadingAction, setLoadingAction] = useState<string>("");
    const [loadingId, setLoadingId] = useState<string>("");
    const [list, setList] = useState<TAffiliatesTransactionsResponse[]>();
    const [filters, setFilters] = useState<any>();
    const [page, setPage] = useState<number>(1);
    const [totalCount, setTotalCounts] = useState<number>(0);

    const handleFilter = async (data: any) => {
        if (data.type) {
            setLoading(true);
            const requestTransactions = await Affiliates.getTransactions({
                type: data.type,
                page: page,
                perPage: 10
            });

            setFilters({
                type: data.type,
                page: page,
                perPage: 10
            });

            if(requestTransactions) {
                setTotalCounts(parseInt(requestTransactions.totalCount));
            }
            if (requestTransactions && requestTransactions.data) {
                setList(requestTransactions.data);
            } else {
                setList([]);
            }

            setLoading(false);
        }
    }

    const actionUpdate = async (id: string, action: string) => {

        setLoadingAction(action);
        setLoadingId(id);

        const actionUpdate = await Affiliates.updateTransaction({
            withdrawId: id,
            status: action
        });

        if (actionUpdate && actionUpdate.data) {
            console.log(actionUpdate);
        }

        setLoadingAction("");
        setLoadingId("");

        const requestTransactions = await Affiliates.getTransactions(filters);

        if(requestTransactions) {
            setTotalCounts(parseInt(requestTransactions.totalCount));
        }

        if (requestTransactions && requestTransactions.data) {            
            setList(requestTransactions.data);
        } else {
            setList([]);
        }
    }

    const pageCount = Math.ceil(totalCount / 10);
    const pageArray = totalCount > 0 ? Array.from(Array(pageCount).keys()) : [];

    return (
        <MainLayout>
            <Filter filters={[
                {
                    name: "type",
                    placeholder: t("Pesquisar por") as string,
                    type: FTypes.select,
                    predefinedValue: "withdraw",
                    options: [
                        {
                            label: "Saques",
                            value: "withdraw",
                        }
                    ]
                }
            ]}
                loading={loading}
                onPress={handleFilter} />

            <Table.Root variant='surface' mt={"4"}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>{t('ID')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Status')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Affiliate_Id')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Username')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Amount')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Register_Date')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Payment_Method')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Withdraw_Account')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Actions')}</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {list && list.length > 0 && list.map((i, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.RowHeaderCell>{i.id}</Table.RowHeaderCell>
                                <Table.Cell>
                                    <Badge color={getColorFromStatus(t(i.status))}>{t(i.status)}</Badge>
                                </Table.Cell>
                                <Table.Cell>{i.affiliateId}</Table.Cell>
                                <Table.Cell>{i.username}</Table.Cell>
                                <Table.Cell>{i.amount}</Table.Cell>
                                <Table.Cell>{i.registerDate}</Table.Cell>
                                <Table.Cell>{i.paymentMethod}</Table.Cell>
                                <Table.Cell>{i.paymentInformation[0].value.includes("@") ? i.paymentInformation[0].value : i.paymentInformation[1].value}</Table.Cell>
                                <Table.Cell>
                                    {i.status === "Pending" &&
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                            {loadingId !== i.withdrawalId && <Tooltip content={"Aceitar"}>
                                                <IconButton color='green' radius='full' onClick={() => actionUpdate(i.withdrawalId, "accept")}>
                                                    <CheckIcon size={20} />
                                                </IconButton>
                                            </Tooltip>}

                                            {loadingId !== i.withdrawalId && <Tooltip content={"Recusar"}>
                                                <IconButton color='red' radius='full' onClick={() => actionUpdate(i.withdrawalId, "reject")}>
                                                    <X size={20} />
                                                </IconButton>
                                            </Tooltip>}

                                            {loadingId === i.withdrawalId && loadingAction === "accept" && <Tooltip content={"Aceitar"}>
                                                <IconButton color='green' radius='full'>
                                                    <Spinner />
                                                </IconButton>
                                            </Tooltip>}

                                            {loadingId === i.withdrawalId && loadingAction === "reject" && <Tooltip content={"Recusar"}>
                                                <IconButton color='red' radius='full'>
                                                    <Spinner />
                                                </IconButton>
                                            </Tooltip>}
                                        </div>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )
                    })
                    }
                </Table.Body>
            </Table.Root>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={PaginateContainer}>
                    {pageArray && pageArray.map((p, pidx) => {
                        return (
                            <div key={pidx} onClick={async () => {
                                const curFilteres = { ...filters };
                                curFilteres['page'] = p + 1;
                                setPage(p + 1);
                                const getPendingTransactions = await Affiliates.getTransactions(curFilteres)

                                setFilters(curFilteres);

                                if (getPendingTransactions && getPendingTransactions.data) {
                                    setList(getPendingTransactions.data);
                                }
                            }} className={page === p + 1 ? `${PageItem} active` : PageItem}>
                                {p + 1}
                            </div>
                        )
                    })}
                </div>
            </div>
        </MainLayout>
    )
}

export default AffiliatesTransactions;