import { TWithdrawalsRequest } from "src/@types/withdrawals";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/transactions/withdrawals";

const getById = async (data: TWithdrawalsRequest) => GenericDAO.getByIdWithParams(route, parseInt(data.clientId), [
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "pageSize",
        value: data.pageSize as any,
    }
], true);

export default {
    getById
};