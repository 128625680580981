import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <h1>Oops! Página não encontrada</h1>
      <p>Acho que você se perdeu...</p>
      <img
        src="https://i.imgur.com/qIufhof.png" // Aqui está um link para uma imagem engraçada, pode substituir por qualquer outra.
        alt="404 funny"
        style={{ maxWidth: 300 }}
      />
      <p>
        Não se preocupe, volte para a <Link to="/">página inicial</Link>!
      </p>
    </div>
  );
};

export default Unauthorized;
