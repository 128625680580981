export type TBonusRequest = {
    clientId: string;
    pageSize?: number;
    page?: number;
}

export type TBonusCancelRequest = {
    clientId: string;
    bonusId: string;
    notes: string;
}

export type TBonusActiveRequest = {
    bonusId: string;
    value: number;
    clientId: number;
}

export enum EBonusTabs {
    bonus = "bonus",
    limits = "limits",
    restrictions = "restrictions",
    emails = "emails",
    messages = "messages",
    transactions = "transactions",
    deposits = "deposits",
    withdrawals = "withdrawals",
    logins = "logins",
    statistics = "statistics",
    notes = "notes"
}

export type TBonusResponse = {
    acceptanceType: string;
    amount: number;
    clientId: number;
    created: string;
    description: string;
    name: string;
    partnerBonusId: string;
    realAmount: number;
    resultType: string;
    toWagerAmount: number;
    wageredAmount: number;
    winAmount: number;
}

export type TBonusList = {
    description: string;
    details: {
        maxAmount: number;
        minAmount: number;
    },
    endDate: string;
    expirationDays: number;
    id: string;
    isDeleted: boolean;
    name: string;
    startDate: string;
}