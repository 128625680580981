import { Button, Dialog, Flex, Select, Spinner, Text, TextField } from "@radix-ui/themes";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TAddTransactionsData, TAddTransactionsType } from "src/@types/transactions";
import CurrencyInput from "../CurrencyInput";
import { removeCurrencyFormatting } from "src/utils";
import { useEffect, useState } from "react";
import Bonus from "src/infraestructure/services/api/Bonus";
import { TBonusList } from "src/@types/bonus";
import Transactions from "src/infraestructure/services/api/Transactions";
import { BookmarkIcon } from "lucide-react";

export type TAddTransactionProps = {
    userId: string;
}

const AddTransaction = ({ userId }: TAddTransactionProps) => {
    const listActions = [
        {
            name: "Adicionar Saldo",
            value: "add-balance"
        },
        {
            name: "Adicionar Bônus",
            value: "add-bonus"
        },
        {
            name: "Adicionar Pagamento",
            value: "add-payment"
        },
        {
            name: "Adicionar Correção de Bônus",
            value: "add-bonus-correction"
        }
    ];
    const paymentSystemList = [
        {
            value: "Pay2Free",
        },
        {
            value: "PixtoPay"
        },
        {
            value: "PagFast"
        },
        {
            value: "Paybrokers",
        },
        {
            value: "Paag",
        }
    ];
    const { t } = useTranslation();
    const [bonusList, setBonusList] = useState<TBonusList[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm<TAddTransactionsType>()
    const onSubmit: SubmitHandler<TAddTransactionsType> = async (data) => {
        setLoading(true);
        let dataAny: TAddTransactionsData = {
            notes: data.type === "add-bonus" ? bonusList?.filter((i) => String(i.id) === data.bonusId)[0].name : data.notes,
            value: removeCurrencyFormatting(data.value)
        };

        if (data.type === "add-bonus") {
            dataAny['bonusId'] = data.bonusId
        }

        if (data.type === "add-payment") {
            dataAny['paymentName'] = data.paymentName
        }
        const addTransactionRequest = await Transactions.put(userId, data.type, dataAny);
        setLoading(false);
        setOpen(false);
    }

    useEffect(() => {
        getBonusList();
    }, []);

    const getBonusList = async () => {
        const bonusListRequest = await Bonus.getList();

        if (bonusListRequest && bonusListRequest.data) {
            setBonusList(bonusListRequest.data);
        }
    }

    useEffect(() => {
        if(!open) {
            reset();
        }
    }, [open])

    return (
        <>
            <Dialog.Root open={open} onOpenChange={setOpen}>
                <Dialog.Trigger>
                    <Button variant="classic">{t('Add')}</Button>
                </Dialog.Trigger>

                <Dialog.Content maxWidth="450px">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Dialog.Title>{t('Add_Transaction')}</Dialog.Title>
                        <Dialog.Description size="2" mb="4">
                            {t('Add_Transaction_Description')}
                        </Dialog.Description>

                        <Flex direction="column" gap="3">
                            <Controller
                                control={control}
                                name="type"
                                defaultValue={listActions[0].value}
                                rules={{
                                    required: true
                                }}
                                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                    <Select.Root value={value} onValueChange={onChange}>
                                        <Select.Trigger />
                                        <Select.Content>
                                            <Select.Group>
                                                <Select.Label>{t('Select_Type')}</Select.Label>
                                                {listActions.map((i, index) => {
                                                    return (
                                                        <Select.Item key={index} value={i.value}>{i.name}</Select.Item>
                                                    )
                                                })}
                                            </Select.Group>
                                        </Select.Content>
                                    </Select.Root>
                                )}
                            />
                            {errors.type &&
                                <p style={{ color: 'red', fontSize: 12 }}>
                                    {t('Type_Required')}
                                </p>
                            }

                            {watch("type") === "add-bonus" && bonusList &&
                                <Controller
                                    control={control}
                                    name="bonusId"
                                    rules={{
                                        required: watch("type") === "add-bonus" ? true : false
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                        <Select.Root value={value} onValueChange={onChange}>
                                            <Select.Trigger />
                                            <Select.Content>
                                                <Select.Group>
                                                    <Select.Label>{t('Select_Bonus')}</Select.Label>
                                                    {bonusList.map((i, index) => {
                                                        return (
                                                            <Select.Item key={i.id} value={String(i.id)}>{i.name}</Select.Item>
                                                        )
                                                    })}
                                                </Select.Group>
                                            </Select.Content>
                                        </Select.Root>
                                    )}
                                />}
                            {errors.bonusId &&
                                <p style={{ color: 'red', fontSize: 12 }}>
                                    {t('Bonus_Required')}
                                </p>
                            }
                            {watch("type") === "add-bonus" && watch("bonusId") && bonusList && bonusList?.filter((i) => String(i.id) === watch("bonusId")).length > 0 && <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                    {bonusList?.filter((i, idx) => String(i.id) === watch("bonusId"))[0].details && <div>Mín: <strong>R${bonusList?.filter((i, idx) => String(i.id) === watch("bonusId"))[0].details.minAmount}</strong></div>}
                                    {bonusList?.filter((i, idx) => String(i.id) === watch("bonusId"))[0].details && <div>Máx: <strong>R${bonusList?.filter((i, idx) => String(i.id) === watch("bonusId"))[0].details.maxAmount}</strong></div>}
                                </div>
                            }
                            
                            {watch("type") === "add-payment" &&
                                <Controller
                                    control={control}
                                    name="paymentName"
                                    rules={{
                                        required: watch("type") === "add-payment" ? true : false
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                        <Select.Root value={value} onValueChange={onChange}>
                                            <Select.Trigger />
                                            <Select.Content>
                                                <Select.Group>
                                                    <Select.Label>{t('Select_PaymentName')}</Select.Label>
                                                    {paymentSystemList.map((i, index) => {
                                                        return (
                                                            <Select.Item key={i.value} value={i.value}>{i.value}</Select.Item>
                                                        )
                                                    })}
                                                </Select.Group>
                                            </Select.Content>
                                        </Select.Root>
                                    )}
                                />}
                            {errors.paymentName &&
                                <p style={{ color: 'red', fontSize: 12 }}>
                                    {t('Payment_Required')}
                                </p>
                            }

                            <TextField.Root {...register("notes", {
                                required: watch("type") !== "add-bonus" ? true : false
                            })} placeholder="Nota" />
                            {errors.notes &&
                                <p style={{ color: 'red', fontSize: 12 }}>
                                    {t('Notes_Required')}
                                </p>
                            }

                            <Controller
                                name="value"
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                            {errors.notes &&
                                <p style={{ color: 'red', fontSize: 12 }}>
                                    {t('Value_Required')}
                                </p>
                            }
                        </Flex>

                        <Flex gap="3" mt="4" justify="end">
                            <Dialog.Close>
                                <Button variant="soft" color="gray">
                                    {t('Cancel')}
                                </Button>
                            </Dialog.Close>
                            {/* <Dialog.Close> */}
                            {!loading && <Button type="submit">{t('Save')}</Button>}
                            {loading && <Button disabled variant="classic">
                                <Spinner loading>
                                    <BookmarkIcon />
                                </Spinner>
                                {t('Save')}
                            </Button>}
                            {/* </Dialog.Close> */}
                        </Flex>
                    </form>
                </Dialog.Content>
            </Dialog.Root>
        </>
    );
}

export default AddTransaction;