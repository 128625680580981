import { Button, Dialog, Flex, Spinner } from '@radix-ui/themes';
import { BookmarkIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type TResetProps = {
    loading: boolean;
    onFinish: () => void;
}

const ResetPassModal = ({ loading, onFinish }: TResetProps) => {

    const { t } = useTranslation();

    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <Button variant="classic">{t('Reset_Password')}</Button>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
                <Dialog.Title>Tem certeza que deseja resetar a senha?</Dialog.Title>
                <Flex gap="3" mt="4" justify="end">
                    <Dialog.Close>
                        <Button variant="soft" color="gray">
                            Voltar
                        </Button>
                    </Dialog.Close>
                    <Dialog.Close>
                        <Button onClick={onFinish} type='submit' disabled={loading} color="red">
                            {loading && <Spinner loading>
                                <BookmarkIcon />
                            </Spinner>}
                            Sim
                        </Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default ResetPassModal;