import GenericDAO from "../GenericDAO";

const route = "/v1/projects";
const routeRolesList = "/v1/projects/roles/list";

const get = async () => GenericDAO.get(route, null, undefined, false);
const getRoles = async () => GenericDAO.get(routeRolesList, null, undefined, false);

export default {
  get,
  getRoles
};