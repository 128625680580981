import { Controller } from "react-hook-form";

interface IOptions {
    id: string;
    name: string;
}

type TControlledCheckboxes = {
    options: IOptions[];
    control: any;
    defaultValue?: string[];
    name: string;
}

const ControlledCheckboxGroup = ({ control, name, options }: TControlledCheckboxes) => {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <>
          {options.map((option) => (
            <div key={option.id}>
              <input
                type="checkbox"
                value={option.id}
                checked={field.value.includes(option.id)}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...field.value, option.id]
                    : field.value.filter((item: any) => item !== option.id);
                  field.onChange(newValue);
                }}
              />
              <label>{option.name}</label>
            </div>
          ))}
        </>
      )}
    />
  );
};

export default ControlledCheckboxGroup;
