import GenericDAO from "../GenericDAO";
import { TAffiliatesSearch, TAffiliatesStatistics, TAffiliatesTransactions, TAffiliatesUpdateTransaction, TAffiliatesWithdrawals } from "src/@types/affiliates";

const route = "/v1/affiliates/search";
const routeTransactions = "/v1/affiliates/transactions";
const routeWithdrawals = "/v1/affiliates/:id/withdrawals";
const routeStatistics = "/v1/affiliates/:id/statistics";
const updateTransactionRoute = "/v1/affiliates/update-transaction/:id";

const updateTransaction = async (data: TAffiliatesUpdateTransaction) => GenericDAO.putByIdWithParamsType(updateTransactionRoute, data.withdrawId, undefined, {}, [
    {
        name: "status",
        value: data.status as any,
    },
], true);

const getTransactions = async (data: TAffiliatesTransactions) => GenericDAO.get(routeTransactions, null, [
    {
        name: "type",
        value: data.type as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "perPage",
        value: data.perPage as any,
    },
], true);

const get = async (data: TAffiliatesSearch) => GenericDAO.get(route, null, [
    {
        name: "email",
        value: data.email as any,
    },
], true);

const getWithdrawals = async (data: TAffiliatesWithdrawals) => GenericDAO.getByIdWithParams(routeWithdrawals, parseInt(data.affiliateId), [
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "perPage",
        value: data.perPage as any,
    },
], true);

const getStatistics = async (data: TAffiliatesStatistics) => GenericDAO.getByIdWithParams(routeStatistics, parseInt(data.affiliateId), [
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "perPage",
        value: data.perPage as any,
    },
], true);

export default {
    get,
    getWithdrawals,
    getStatistics,
    getTransactions,
    updateTransaction
};