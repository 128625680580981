import { TAddTransactionsData, TTransactionsRequest } from "src/@types/transactions";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/transactions/:type";
const routePut = "/v1/clients/:id/transactions/:type";;

const getById = async (data: TTransactionsRequest) => GenericDAO.getByIdWithParamsType(route, parseInt(data.clientId), data.type, [
    {
        name: "clientId",
        value: data.clientId as any,
    },
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "typeId",
        value: data.typeId as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "pageSize",
        value: data.pageSize as any,
    },
    {
        name: "limit",
        value: data.limit as any,
    }
], true);

const put = async (clientId: string, type: string, data: TAddTransactionsData) => GenericDAO.putByIdWithParamsType(route, clientId, type, data, undefined, true);

export default {
    getById,
    put
};