import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TDefaultResponse } from 'src/@types/transactions';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import TablePaginated from 'src/components/TablePaginated';
import ViewMessage from 'src/components/ViewMessage';
import WithdrawalsRequests from 'src/infraestructure/services/api/WithdrawalsRequests';

const WithdrawalRequests = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<TDefaultResponse>();
    const [filteres, setFilteres] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(1);

    const handleFilter = async (data: any) => {
        setLoading(true);
        const getWR = await WithdrawalsRequests.getById({
            clientId: data.PersonalId,
            page: currentPage,
            pageSize: 10,
            startDate: data.startDate,
            endDate: data.endDate
        });

        setFilteres({
            clientId: data.PersonalId,
            pageSize: 10,
            startDate: data.startDate,
            endDate: data.endDate
        })

        if (getWR && getWR.data && getWR.data.length > 0) {
            setList(getWR);
        }
        setLoading(false);
    }

    const handleChangePage = async (page: number) => {
        const getWR = await WithdrawalsRequests.getById({
            clientId: filteres.PersonalId,
            page: page,
            pageSize: 10,
            startDate: filteres.startDate,
            endDate: filteres.endDate
        });

        if (getWR && getWR.data) {
            setList(getWR);
            setCurrentPage(page);
        }
    }


    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "search",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "PersonalId",
                        options: [
                            {
                                label: "ID do Jogador",
                                value: "PersonalId",
                            }
                        ],
                    },
                    {
                        name: "startDate,endDate",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.range,
                        predefinedValue: [startOfDay(new Date()), endOfDay(addDays(new Date(), +1))],
                        format: 'DD-MM-YY'
                    }
                ]}
                loading={loading}
                onPress={handleFilter}
            />
            <TablePaginated
                totalItems={list?.pagination.totalItems ?? 0}
                data={list?.data}
                keys={[
                    {
                        name: "documentId",
                        type: "string"
                    },
                    {
                        name: "clientName",
                        type: "string"
                    },
                    {
                        name: "info",
                        type: "string"
                    },
                    {
                        name: "amount",
                        type: "money"
                    },
                    {
                        name: "paymentSystem",
                        type: "string"
                    },
                    {
                        name: "paymentSystemMessage",
                        type: "string",
                        children: ((response) => {
                            if (response.paymentSystemMessage !== "No Message") {
                                return (
                                    <>
                                        <ViewMessage text={response.paymentSystemMessage} description={response.title} />
                                    </>
                                )
                            } else {
                                return (
                                    <>-</>
                                )
                            }
                        })
                    },
                    {
                        name: "stateName",
                        type: "tag"
                    },
                    {
                        name: "created",
                        type: "string"
                    }
                ]}
                perPage={10}
                onPageChange={handleChangePage}
            />
        </MainLayout>
    );
}

export default WithdrawalRequests;