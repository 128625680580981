import { TGgrRequest } from "src/@types/ggr";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/ggr";

const getById = async (data: TGgrRequest) => GenericDAO.getByIdWithParams(route, parseInt(data.clientId), [
    {
        name: "clientId",
        value: data.clientId as any,
    },
    {
        name: "date",
        value: data.date as any,
    }
], true);

export default {
    getById
};