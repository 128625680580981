const config = {
  LIVECHAT_API_URL:
    "https://api.livechatinc.com/v3.4",
  USERNAMES_LIVECHAT:
    [
      {
        email: "claud.oig2023@gmail.com",
        nome: "Claúdia"
      },
      {
        email: "danyelavaz12@gmail.com",
        nome: "Danyela Vaz"
      },
      {
        email: "emanoel7games@gmail.com",
        nome: "Emanoel"
      },
      {
        email: "ericka7games@gmail.com",
        nome: "Ericka"
      },
      {
        email: "georgepxto@gmail.com",
        nome: "George Peixoto"
      },
      {
        email: "joaopaulochat2023@gmail.com",
        nome: "João Paulo"
      },
      {
        email: "karenligia01@gmail.com",
        nome: "Karen Ligia"
      },
      {
        email: "laiomerculis@gmail.com",
        nome: "Laio Merculis"
      },
      {
        email: "larissagabriele0807@gmail.com",
        nome: "Larissa Gabriele"
      },
      {
        email: "lisdemacedo@gmail.com",
        nome: "Lis Macedo"
      },
      {
        email: "marcoalu7games@gmail.com",
        nome: "Marcos"
      },
      {
        email: "mariaksilva860@gmail.com",
        nome: "Maria K Silva"
      },
      {
        email: "matheuspeixotooig@gmail.com",
        nome: "Matheus Peixoto"
      },
      {
        email: "rayanecelestino04@gmail.com",
        nome: "Rayane Celestina"
      },
      {
        email: "samoelcarvalhobet7@gmail.com",
        nome: "Samoel Carvalho"
      },
      {
        email: "vivianemendesdapaz@gmail.com",
        nome: "Viviane Mendes"
      },
      {
        email: "yagami8709@gmail.com",
        nome: "Jhonny"
      },
      {
        email: "ottaraven@gmail.com",
        nome: "Bruna"
      },
      {
        email: "rdcarvalho125@gmail.com",
        nome: "Ronald"
      }
    ]

};

export default config;