import { createUseStyles } from "react-jss";

const useViewGGRStyles = createUseStyles({
    Container: {

    },
    Title: {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: 20
    },
    Description: {
        fontSize: 15,
        fontWeight: 600,
        color: '#fff'
    }
})

export default useViewGGRStyles;