import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AuthContextData {
  signed: boolean;
  user: object | null;
  status: string;
  setStatus: Dispatch<SetStateAction<string>>;
  Logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: any) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<object | null>(null);
  const [signed, setSigned] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    const storagedToken = localStorage.getItem('@App:token');
    const storagedUser = localStorage.getItem('@App:user');

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      setStatus("active");
      setSigned(true);
    } 
  }, []);

  function Logout() {
    setUser(null);
    setSigned(false);
    localStorage.removeItem("@App:token");
    localStorage.removeItem("@App:user");
  }

  return (
    <AuthContext.Provider
      value={{ signed: signed, user, Logout, status, setStatus }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}