import { TLimitsRequest, TLimitsRequestType } from "src/@types/limits";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/limits";
const removeRestrictionsRoute = "/v1/clients/:id/remove-limits";
const removeLimitType = "/v1/clients/:id/remove-limits/:type";

const getById = async (data: TLimitsRequest) => GenericDAO.replaceGetById(route, data.clientId, true);
const save = async (data: TLimitsRequest) => GenericDAO.putWithId(removeRestrictionsRoute, data, undefined, true);
const update = async (data: TLimitsRequestType) => GenericDAO.putByIdWithParamsType(removeLimitType, data.clientId, data.type, {}, undefined, true);

export default {
    getById,
    save,
    update
};