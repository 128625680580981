import { TLogsRequest } from "src/@types/logs";
import GenericDAO from "../GenericDAO";

const route = "/v1/users/logs";

const get = async (data: TLogsRequest) => GenericDAO.get(route, null, [
  {
    name: "startDate",
    value: data.startDate as any,
  },
  {
    name: "endDate",
    value: data.endDate as any,
  },
  {
    name: "page",
    value: data.page as any,
  },
  {
    name: "limit",
    value: data.limit as any,
  }
], true);

export default {
  get
};