import { Button, Dialog, Flex, IconButton, Select, Spinner, TextArea, Tooltip } from '@radix-ui/themes';
import { Ban, BookmarkIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Block from 'src/infraestructure/services/api/Block';

type TBlockUserForm = {
    note: string;
    isBlock: string;
}

type TBlockClientProps = {
    clientId: string;
    isLocked: boolean;
    onFinish: (result: string) => void;
}

const BlockClient = ({ clientId, isLocked, onFinish }: TBlockClientProps) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    
    const [open, setOpen] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm<TBlockUserForm>()
    const onSubmit: SubmitHandler<TBlockUserForm> = async (data) => {
        setLoading(true);
        const request = await Block.put({
            clientId: clientId,
            note: data.note
        });

        if(request && request.data) {
            toast.success(t(request.data));
            onFinish(data.isBlock);
        }
        setLoading(false);
        setOpen(false);
    }

    useEffect(() => {
        if(!open) {
            reset();
        }
    }, [open])

    useEffect(() => {
        setValue('isBlock', isLocked ? "0" : "1");
    }, [isLocked]);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Tooltip content={isLocked ? `Desbloquear Usuário` : `Bloquear Usuário`}>
                <Dialog.Trigger>
                    <IconButton radius='full' color={isLocked ? 'green' : 'red'}>
                        <Ban size={17} />
                    </IconButton>
                </Dialog.Trigger>
            </Tooltip>

            <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
                <Dialog.Title>Motivo de Bloqueio</Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction="column" gap="3">

                        <Controller
                            control={control}
                            name="isBlock"
                            defaultValue={isLocked ? "0" : "1"} 
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, value }, formState, fieldState }) => (
                                <Select.Root value={value} onValueChange={onChange}>
                                    <Select.Trigger />
                                    <Select.Content>
                                        <Select.Group>
                                            <Select.Label>{t('Select_Block')}</Select.Label>
                                            <Select.Item value={'1'}>{t('Block')}</Select.Item>
                                            <Select.Item value={'0'}>{t('Unblock')}</Select.Item>
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            )}
                        />
                        {errors.isBlock &&
                            <p style={{ color: 'red', fontSize: 12, margin: 0 }}>
                                {t('Type_Required')}
                            </p>
                        }

                        <label>
                            <TextArea
                                defaultValue=""
                                {...register('note', {
                                    required: true
                                })}
                                placeholder="Motivo..."
                            />
                        </label>

                        {errors.note &&
                            <p style={{ color: 'red', fontSize: 12, margin: 0 }}>
                                {t('Note_Required')}
                            </p>
                        }
                    </Flex>

                    <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                            <Button variant="soft" color="gray">
                                {t('Back')}
                            </Button>
                        </Dialog.Close>

                        <Button type='submit' disabled={loading} color="red">
                            {loading && <Spinner loading>
                                <BookmarkIcon />
                            </Spinner>}
                            {!loading && t('Save')}
                        </Button>

                    </Flex>
                </form>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default BlockClient;