import { createUseStyles } from "react-jss";

const useModalReceiptStyles = createUseStyles({
    TextTitle: {
        fontSize: 15,
        color: '#f1f1f1',
    },
    TextInfo: {
        fontSize: 16,
        color: '#f1f1f1',
        fontWeight: '600'
    }
})

export default useModalReceiptStyles;