import { TEmailsRequest } from "src/@types/emails";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/emails";

const getById = async (data: TEmailsRequest) => GenericDAO.getByIdWithParams(route, parseInt(data.clientId), [
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "pageSize",
        value: data.perPage as any,
    }
], true);

export default {
    getById
};