/**
 * @namespace GenericDAO
 */
import axios from "axios";
// import User from './Auth/User';
import toast from "react-hot-toast";
import config from "src/config/livechat/config";
import { fetchWithTimeout } from "src/utils";
import { ClientError, ServerError } from "../../../errors";
import Auth from "./Auth";
import i18n from 'i18next';
/**
 * GenericDAO é um middleware que abstrai a complexidade em todas as requisições
 * para a API. Ele possui 3 ações:
 * - get: para listar todos os recursos do endpoint, ou um em específico ao passar o id como parâmetro;
 * - save: para criar um novo recurso, ou editar um específico ao passar o id como parâmetro;
 * - remove: pra excluir um recurso.
 *
 * Os endpoints podem exigir autenticação ou serem rotas abertas. Por padrão as ações
 * do GenericDAO são autenticadas na API. Caso seja um rota aberta, informar o parâmetro auth como false.
 */

/**
 * @description get serve tanto para obter todos os dados, quanto para obter um registro por ID
 * @memberOf GenericDAO
 *
 * @param {string} route Rota da API
 * @param {(number | null)} [id=null] Caso seja solicitado apenas um recurso, é fornecido o id, caso sejam todos o id não é informado.
 * @param {boolean} [auth=true] Indica se a rota é autenticada, ou não. Por padrão, todas as rotas exigem autenticação.
 *
 * @returns {Promise<any>} Promise que retorna o recurso solicitado
 *
 * @throws ClientError
 * @throws ServerError
 */
const get = async (
  route: string,
  id: number | null = null,
  filters?: {
    name: string;
    value: string;
  }[],
  auth: boolean = true
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      };
    }
    let stringRequest: string = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    if (id?.toString()) {
      stringRequest = stringRequest + `/${id}`;
    } else {
      if (filters) {
        stringRequest = stringRequest + "?";
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    }
    response = await fetchWithTimeout(stringRequest, {
      method: "GET",
      headers: headers,
    });

    const responseJSON = await response.json();
    
    if (responseJSON.code === 200 || responseJSON.statusCode === 200 || response.status === 200) {
      return responseJSON;
    } else {
      // throw new Error(`HTTP error! status: ${response.status}`);
      if(responseJSON.message) {
        toast.error(i18n.t(responseJSON.message));
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }
  } catch (e: any) {
    console.log("Catch", e);
    toast.error('Erro ao realizar busca.');
  }
};

const saveLivechat = async (
  route: string,
  data: Partial<any> | void,
): Promise<any> => {
  try {
    let response: any;
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${process.env.REACT_APP_LIVE_TOKEN}`
    };

    if (data) {
      response = await axios.post(`${config.LIVECHAT_API_URL}${route}`, JSON.stringify(data), {
        headers: headers,
      });
    } else {
      response = await axios.post(`${config.LIVECHAT_API_URL}${route}`, JSON.stringify({}), {
        headers: headers,
      });
    }

    const responseJSON = response.data;

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      return responseJSON;
    }
  } catch (e) {
    console.log("Catch", e);
    toast.error('Ocorreu um erro ao tentar conectar com o servidor.');
  }
};

/**
 * @description save serve tanto para salvar, quanto para editar
 * @memberOf GenericDAO
 *
 * @param {string} route Rota da API
 * @param {any} data Dados a serem salvos
 * @param {(number | null)} [id=null] id Caso seja solicitado editar um recurso, é fornecido o id, caso seja para salvar o id é null ou não fornecido
 * @param {boolean} [auth=true] Indica se a rota é autenticada, ou não. Por padrão, todas as rotas exigem autenticação.
 *
 * @returns {Promise<any>} Promise que retorna a resposta da API
 *
 * @throws {ClientError} Sessão Expirada
 * @throws {ServerError} API com indisponibilidade
 * @throws Outros erros que possam ser gerados
 */

const save = async (
  route: string,
  data: Partial<any>,
  auth: boolean = false,
  timeout: number = 60000,
): Promise<any> => {
  try {
    let response: any;
    let headers: any = {
      'Content-Type': 'application/json'
    };

    if (auth) {
      // const userData = localStorage.getItem("@App:user");

      // if (userData) {
      //   const userDataJson: TProfileInfo = JSON.parse(userData);
      //   console.log(userDataJson);

      //   if (!userDataJson.Scope?.backend.includes(route)) {
      //     return {
      //       Status: 400,
      //       Message: "Sem permissão para executar essa ação."
      //     };
      //   }
      // }

      const token = Auth.getToken();
      headers['Authorization'] = `Bearer ${token}`;
      // headers = {
      //   'Authorization': `Bearer ${token}`
      // };
    }

    // response = await fetchWithTimeout(
    //   `${process.env.REACT_APP_OFFICE_PUBLIC_BASE_URL}${route}`,
    //   {
    //     method: "POST",
    //     headers: headers,
    //     body: JSON.stringify(data),

    //   }
    // , timeout);

    // const responseJSON = await response.json();

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    response = await axios.post(url, JSON.stringify(data), {
      headers: headers,
    });

    const responseJSON = response.data;

    if (responseJSON.statusCode !== 200 && responseJSON.statusCode !== 201) {
      return toast.error(i18n.t(responseJSON.message));
    } else {
      if(responseJSON.message) {
        toast.success(i18n.t(responseJSON.message));
      }
      return responseJSON;
    }
  } catch (e: any) {
    console.log("Catch", e);
    toast.error(i18n.t(e.message) ?? 'Ocorreu um erro ao tentar conectar com o servidor.');
  }
};

const saveFormData = async (
  route: string,
  data: any,
  auth: boolean = false,
  timeout: number = 60000,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
      };
    }

    const formData = new FormData();
    formData.append("image", data);

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    response = await fetchWithTimeout(
      url,
      {
        method: "POST",
        headers: headers,
        body: formData,
      }
      , timeout);

    const responseJSON = await response.json();

    return responseJSON;
  } catch (e) {
    //Tratar Erros
  }
};


const savePut = async (
  route: string,
  data: Partial<any>,
  auth: boolean = false,
  timeout: number = 60000,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      // const userData = localStorage.getItem("@App:user");

      // if (userData) {
      //   const userDataJson: TProfileInfo = JSON.parse(userData);
      //   console.log(userDataJson);

      //   if (!userDataJson.Scope?.backend.includes(route)) {
      //     return {
      //       Status: 400,
      //       Message: "Sem permissão para executar essa ação."
      //     };
      //   }
      // }

      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json'
      };
    }

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;
    
    response = await fetchWithTimeout(
      url,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(data),
      }
      , timeout);

    const responseJSON = await response.json();

    return responseJSON;
  } catch (e) {
    //Tratar Erros
  }
};

const savePatch = async (
  route: string,
  id?: string,
  data?: Partial<any>,
  auth: boolean = false
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
      };
    }

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    if (id) {
      response = await fetchWithTimeout(
        `${url}/${id}`,
        {
          method: "PATCH",
          headers: headers,
        }
      );
    } else {
      response = await fetchWithTimeout(
        url,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(data)
        }
      );
    }

    const responseJSON = await response.json();

    return responseJSON;
  } catch (e) {
    //Tratar Erros
  }
};

/**
 * @description remove um recurso da API
 * @memberOf GenericDAO
 *
 * @param {string} route Rota da API
 * @param {number} id id do recurso a ser removido
 * @param {boolean} [auth=true] Indica se a rota é autenticada, ou não. Por padrão, todas as rotas exigem autenticação.
 *
 * @returns {Promise<any>} Promise que retorna a resposta da API
 *
 * @throws {ClientError} Sessão Expirada
 * @throws {ServerError} API com indisponibilidade
 * @throws Outros erros que possam ser gerad
 * os
 */
const remove = async (
  route: string,
  id: string,
  auth: boolean = true
): Promise<any> => {
  try {
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
      };
    }

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route.replace(":id", id)}`;

    const response: any = await fetchWithTimeout(
      url,
      { method: "DELETE", headers }
    );

    const responseJSON = await response.json();

    if (responseJSON.statusCode !== 200 && responseJSON.statusCode !== 201) {
      return toast.error(i18n.t(responseJSON.message));
    } else {
      if(responseJSON.message) {
        toast.success(i18n.t(responseJSON.message));
      }
      return responseJSON;
    }

  } catch (e) {
    // Erros podem ser do Axios ou outros. Caso sejam erros http 4xx (401, 402, etc)
    // é gerado um ClientError. Caso sejam erros http 5xx (500, 501, etc) é lançado
    // um erro ServerError. Caso sejam outros tipos de erros http ou erros de outra natureza,
    // o erro é relançado para a função que chamou.
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status;

      if (statusCode?.toString() === "401") {
        // localStorage.removeItem(process.env.NEXT_PUBLIC_IS_LOGGED as string);
        // localStorage.setItem(process.env.NEXT_PUBLIC_IS_LOGGED as string, 'false');
        // localStorage.removeItem(process.env.NEXT_PUBLIC_APP_TOKEN_KEY as string);
        throw new (ClientError as any)("Tente logar novamente");
      }

      if (
        statusCode?.toString() !== "401" &&
        statusCode?.toString().startsWith("4")
      )
        throw new (ClientError as any)(
          `Erro na validação da regra de negócio! (${e.response?.data.data})`
        );

      if (statusCode?.toString().startsWith("5"))
        throw new (ServerError as any)("Servidor indisponível.");
    }
    throw e;
  }
};

/**
 * @description remove um recurso da API
 * @memberOf GenericDAO
 *
 * @param {string} route Rota da API
 * @param {number} id id do recurso a ser removido
 * @param {boolean} [auth=true] Indica se a rota é autenticada, ou não. Por padrão, todas as rotas exigem autenticação.
 *
 * @returns {Promise<any>} Promise que retorna a resposta da API
 *
 * @throws {ClientError} Sessão Expirada
 * @throws {ServerError} API com indisponibilidade
 * @throws Outros erros que possam ser gerados
 */
const getById = async (
  route: string,
  id: string,
  auth: boolean = true
): Promise<any> => {
  try {
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      };
    }
    // const response: any = await fetchWithTimeout(
    //   `${process.env.REACT_APP_OFFICE_PUBLIC_BASE_URL}${route}/${id}`,
    //   { method: "GET", headers }
    // );

    // const responseJSON = await response.json();

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    const response: any = await axios.get(`${url}/${id}`, {
      headers: headers
    });

    const responseJSON = await response.data;

    if (responseJSON.statusCode !== 200 && responseJSON.statusCode !== 201) {
      return toast.error(i18n.t(responseJSON.message));
    } else {
      return responseJSON;
    }

  } catch (e) {
    // Erros podem ser do Axios ou outros. Caso sejam erros http 4xx (401, 402, etc)
    // é gerado um ClientError. Caso sejam erros http 5xx (500, 501, etc) é lançado
    // um erro ServerError. Caso sejam outros tipos de erros http ou erros de outra natureza,
    // o erro é relançado para a função que chamou.
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status;

      if (statusCode?.toString() === "401") {
        // localStorage.removeItem(process.env.NEXT_PUBLIC_IS_LOGGED as string);
        // localStorage.setItem(process.env.NEXT_PUBLIC_IS_LOGGED as string, 'false');
        // localStorage.removeItem(process.env.NEXT_PUBLIC_APP_TOKEN_KEY as string);
        // throw new (ClientError as any)("Tente logar novamente");
        toast.error('Sessão inválida ou expirada.');
      }

      if (
        statusCode?.toString() !== "401" &&
        statusCode?.toString().startsWith("4")
      )

        toast.error('Erro na validação da regra de negócio');
      // throw new (ClientError as any)(
      //   `Erro na validação da regra de negócio! (${e.response?.data.data})`
      // );

      if (statusCode?.toString().startsWith("5"))
        toast.error(i18n.t('Servidor indisponível.'));
      // throw new (ServerError as any)("Servidor indisponível.");
    }
    console.log(e);
    toast.error(i18n.t('Ocorreu um erro interno ao tentar se conectar com o servidor.'));
  }
};

/**
 * @description obtem um recurso da API
 * @memberOf GenericDAO
 *
 * @param {string} route Rota da API
 * @param {number} id id do recurso a ser removido
 * @param {boolean} [auth=true] Indica se a rota é autenticada, ou não. Por padrão, todas as rotas exigem autenticação.
 *
 * @returns {Promise<any>} Promise que retorna a resposta da API
 *
 * @throws {ClientError} Sessão Expirada
 * @throws {ServerError} API com indisponibilidade
 * @throws Outros erros que possam ser gerados
 */
const replaceGetById = async (
  route: string,
  id: string,
  auth: boolean = true
): Promise<any> => {
  try {
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      };
    }

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    const response: any = await axios.get(url.replace(':id', id), {
      headers: headers
    });

    const responseJSON = await response.data;

    if (responseJSON.statusCode !== 200 && responseJSON.statusCode !== 201) {
      return toast.error(i18n.t(responseJSON.message));
    } else {
      return responseJSON;
    }

  } catch (e) {
    // Erros podem ser do Axios ou outros. Caso sejam erros http 4xx (401, 402, etc)
    // é gerado um ClientError. Caso sejam erros http 5xx (500, 501, etc) é lançado
    // um erro ServerError. Caso sejam outros tipos de erros http ou erros de outra natureza,
    // o erro é relançado para a função que chamou.
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status;

      if (statusCode?.toString() === "401") {
        toast.error('Sessão inválida ou expirada.');
      }

      if (
        statusCode?.toString() !== "401" &&
        statusCode?.toString().startsWith("4")
      )

        // toast.error('Erro na validação da regra de negócio');
      // throw new (ClientError as any)(
      //   `Erro na validação da regra de negócio! (${e.response?.data.data})`
      // );

      if (statusCode?.toString().startsWith("5"))
        toast.error('Servidor indisponível.');
      // throw new (ServerError as any)("Servidor indisponível.");
    }
    console.log(e);
    toast.error('Nenhum resultado encontrado.');
  }
};

/**
 * @description obtem um recurso da API
 * @memberOf GenericDAO
 *
 * @param {string} route Rota da API
 * @param {number} id id do recurso a ser removido
 * @param {boolean} [auth=true] Indica se a rota é autenticada, ou não. Por padrão, todas as rotas exigem autenticação.
 *
 * @returns {Promise<any>} Promise que retorna a resposta da API
 *
 * @throws {ClientError} Sessão Expirada
 * @throws {ServerError} API com indisponibilidade
 * @throws Outros erros que possam ser gerados
 */
const getByIdWithParamsType = async (
  route: string,
  id: number | string | null = null,
  type: string | undefined,
  filters?: {
    name: string;
    value: string;
  }[],
  auth: boolean = true,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json'
      };
    }
    let stringRequest: string = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    if (id?.toString()) {
      stringRequest = stringRequest.replace(':id', String(id));
      if(type) {
        stringRequest = stringRequest.replace(":type", type);
      }
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        if(type) {
          stringRequest = stringRequest.replace(":type", type);
        }
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    } else {
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    }
    response = await fetchWithTimeout(stringRequest, {
      method: "GET",
      headers: headers,
    }, 36000);

    const responseJSON = await response.json();
    
    if (responseJSON.code === 200 || responseJSON.statusCode === 200 || response.status === 200) {
      return responseJSON;
    } else {
      toast.error(i18n.t(responseJSON.message));
      // throw new Error(`HTTP error! status: ${response.status}`);
    }

  } catch (e) {
    // Erros podem ser do Axios ou outros. Caso sejam erros http 4xx (401, 402, etc)
    // é gerado um ClientError. Caso sejam erros http 5xx (500, 501, etc) é lançado
    // um erro ServerError. Caso sejam outros tipos de erros http ou erros de outra natureza,
    // o erro é relançado para a função que chamou.
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status;

      if (statusCode?.toString() === "401") {
        // localStorage.removeItem(process.env.NEXT_PUBLIC_IS_LOGGED as string);
        // localStorage.setItem(process.env.NEXT_PUBLIC_IS_LOGGED as string, 'false');
        // localStorage.removeItem(process.env.NEXT_PUBLIC_APP_TOKEN_KEY as string);
        // throw new (ClientError as any)("Tente logar novamente");
        toast.error(i18n.t('Sessão inválida ou expirada.'));
      }

      if (
        statusCode?.toString() !== "401" &&
        statusCode?.toString().startsWith("4")
      )

        toast.error('Erro na validação da regra de negócio');
      // throw new (ClientError as any)(
      //   `Erro na validação da regra de negócio! (${e.response?.data.data})`
      // );

      if (statusCode?.toString().startsWith("5"))
        toast.error(i18n.t('Servidor indisponível.'));
      // throw new (ServerError as any)("Servidor indisponível.");
    }
    console.log(e);
    toast.error(i18n.t('Ocorreu um erro interno ao tentar se conectar com o servidor.'));
  }
};


/**
 * @description obtem um recurso da API
 * @memberOf GenericDAO
 *
 * @param {string} route Rota da API
 * @param {number} id id do recurso a ser removido
 * @param {boolean} [auth=true] Indica se a rota é autenticada, ou não. Por padrão, todas as rotas exigem autenticação.
 *
 * @returns {Promise<any>} Promise que retorna a resposta da API
 *
 * @throws {ClientError} Sessão Expirada
 * @throws {ServerError} API com indisponibilidade
 * @throws Outros erros que possam ser gerados
 */

const getByIdWithParams = async (
  route: string,
  id: number | null = null,
  filters?: {
    name: string;
    value: string;
  }[],
  auth: boolean = true
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
      };
    }
    let stringRequest: string = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    if (id?.toString()) {
      stringRequest = stringRequest.replace(':id', String(id));
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    } else {
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    }
    response = await fetchWithTimeout(stringRequest, {
      method: "GET",
      headers: headers,
    });

    const responseJSON = await response.json();
    
    if (responseJSON.code === 200 || responseJSON.statusCode === 200 || response.status === 200) {
      return responseJSON;
    } else {
      if(responseJSON.message) {
        return toast.error(i18n.t(responseJSON.message));
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

  } catch (e) {
    // Erros podem ser do Axios ou outros. Caso sejam erros http 4xx (401, 402, etc)
    // é gerado um ClientError. Caso sejam erros http 5xx (500, 501, etc) é lançado
    // um erro ServerError. Caso sejam outros tipos de erros http ou erros de outra natureza,
    // o erro é relançado para a função que chamou.
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status;

      if (statusCode?.toString() === "401") {
        // localStorage.removeItem(process.env.NEXT_PUBLIC_IS_LOGGED as string);
        // localStorage.setItem(process.env.NEXT_PUBLIC_IS_LOGGED as string, 'false');
        // localStorage.removeItem(process.env.NEXT_PUBLIC_APP_TOKEN_KEY as string);
        // throw new (ClientError as any)("Tente logar novamente");
        toast.error('Sessão inválida ou expirada.');
      }

      if (
        statusCode?.toString() !== "401" &&
        statusCode?.toString().startsWith("4")
      )

        toast.error('Erro na validação da regra de negócio');
      // throw new (ClientError as any)(
      //   `Erro na validação da regra de negócio! (${e.response?.data.data})`
      // );

      if (statusCode?.toString().startsWith("5"))
        toast.error('Servidor indisponível.');
      // throw new (ServerError as any)("Servidor indisponível.");
    }
    console.log(e);
    toast.error('Ocorreu um erro interno ao tentar se conectar com o servidor.');
  }
};

const saveWithId = async (
  route: string,
  data: Partial<any>,
  auth: boolean = false,
  timeout: number = 60000,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    headers = {
      'Content-Type': 'application/json'
    }

    if (auth) {
      const token = Auth.getToken();

      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    }

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;


    response = await axios.post(url.replace(":id", data.clientId), JSON.stringify(data), {
      headers: headers,
    });

    const responseJSON = response.data;

    if (responseJSON.statusCode !== 200 && responseJSON.statusCode !== 201) {
      return toast.error(i18n.t(responseJSON.message));
    } else {
      return responseJSON;
    }
  } catch (e) {
    console.log("Catch", e);
    toast.error('Ocorreu um erro ao tentar conectar com o servidor.');
  }
};

const putWithId = async (
  route: string,
  data: Partial<any>,
  dataBody?: Partial<any>,
  auth: boolean = false,
  timeout: number = 60000,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    headers = {
      'Content-Type': 'application/json'
    }

    if (auth) {
      const token = Auth.getToken();

      console.log(token);

      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    }

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    response = await axios.put(url.replace(":id", data.clientId), dataBody ? JSON.stringify(dataBody) : {}, {
      headers: headers,
    });

    const responseJSON = response.data;

    console.log(responseJSON);

    if (responseJSON.statusCode !== 200 && responseJSON.statusCode !== 201) {
      return toast.error(i18n.t(responseJSON.message));
    } else {
      if(responseJSON.message) {
        toast.success(i18n.t(responseJSON.message));
      }
      return responseJSON;
    }
  } catch (e: any) {
    console.log("Catch", e);
    if(e?.response?.data?.message) {
      toast.error(i18n.t(e?.response.data.message));
    } else {
      toast.error(i18n.t(e?.message));
    }
  }
};

const putWithTwoId = async (
  route: string,
  lastArg: string,
  data: Partial<any>,
  dataBody?: Partial<any>,
  auth: boolean = false,
  timeout: number = 60000,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    headers = {
      'Content-Type': 'application/json'
    }

    if (auth) {
      const token = Auth.getToken();

      console.log(token);

      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    }

    const url = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    response = await axios.put(url.replace(":id", data.clientId).replace(`:${lastArg}`, data[lastArg]), dataBody ? JSON.stringify(dataBody) : {}, {
      headers: headers,
    });

    const responseJSON = response.data;

    if (responseJSON.statusCode !== 200 && responseJSON.statusCode !== 201) {
      return toast.error(i18n.t(responseJSON.message));
    } else {
      if(responseJSON.message) {
        toast.success(i18n.t(responseJSON.message));
      }
      return responseJSON;
    }
  } catch (e) {
    console.log("Catch", e);
    toast.error('Ocorreu um erro ao tentar conectar com o servidor.');
  }
};

const putByIdWithParamsType = async (
  route: string,
  id: number | string | null = null,
  type: string | undefined,
  data: Partial<any>,
  filters?: {
    name: string;
    value: string;
  }[],
  auth: boolean = true,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json'
      };
    }
    let stringRequest: string = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    if (id?.toString()) {
      stringRequest = stringRequest.replace(':id', String(id));
      if(type) {
        stringRequest = stringRequest.replace(":type", type);
      }
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        if(type) {
          stringRequest = stringRequest.replace(":type", type);
        }
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    } else {
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    }
    response = await fetchWithTimeout(stringRequest, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data)
    }, 36000);

    const responseJSON = await response.json();
    
    if (responseJSON.code === 200 || responseJSON.statusCode === 200 || response.status === 200) {
      if(responseJSON.message) {
        toast.success(i18n.t(responseJSON.message));
      }
      return responseJSON;
    } else {
      toast.error(i18n.t(responseJSON.message));
      // throw new Error(`HTTP error! status: ${response.status}`);
    }

  } catch (e) {
    // Erros podem ser do Axios ou outros. Caso sejam erros http 4xx (401, 402, etc)
    // é gerado um ClientError. Caso sejam erros http 5xx (500, 501, etc) é lançado
    // um erro ServerError. Caso sejam outros tipos de erros http ou erros de outra natureza,
    // o erro é relançado para a função que chamou.
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status;

      if (statusCode?.toString() === "401") {
        // localStorage.removeItem(process.env.NEXT_PUBLIC_IS_LOGGED as string);
        // localStorage.setItem(process.env.NEXT_PUBLIC_IS_LOGGED as string, 'false');
        // localStorage.removeItem(process.env.NEXT_PUBLIC_APP_TOKEN_KEY as string);
        // throw new (ClientError as any)("Tente logar novamente");
        toast.error(i18n.t('Sessão inválida ou expirada.'));
      }

      if (
        statusCode?.toString() !== "401" &&
        statusCode?.toString().startsWith("4")
      )

        toast.error('Erro na validação da regra de negócio');
      // throw new (ClientError as any)(
      //   `Erro na validação da regra de negócio! (${e.response?.data.data})`
      // );

      if (statusCode?.toString().startsWith("5"))
        toast.error(i18n.t('Servidor indisponível.'));
      // throw new (ServerError as any)("Servidor indisponível.");
    }
    console.log(e);
    toast.error(i18n.t('Ocorreu um erro interno ao tentar se conectar com o servidor. Tente novamente.'));
  }
};

const postByIdWithParamsType = async (
  route: string,
  id: number | string | null = null,
  type: string | undefined,
  data: Partial<any>,
  filters?: {
    name: string;
    value: string;
  }[],
  auth: boolean = true,
): Promise<any> => {
  try {
    let response: any;
    let headers = {};

    if (auth) {
      const token = Auth.getToken();

      headers = {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json'
      };
    }
    let stringRequest: string = `${JSON.parse(localStorage.getItem('@App:currentProject') || '{}').apiUrl ?? 'https://api-0.7games.support'}${route}`;

    if (id?.toString()) {
      stringRequest = stringRequest.replace(':id', String(id));
      if(type) {
        stringRequest = stringRequest.replace(":type", type);
      }
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        if(type) {
          stringRequest = stringRequest.replace(":type", type);
        }
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    } else {
      if (filters) {
        stringRequest = stringRequest.replace(':id', String(id)) + "?";
        filters.map((item, index) => {
          if (item.value && item.value !== "undefined" && item.value !== undefined) {
            stringRequest =
              stringRequest +
              `${item.name}=${item.value}${filters.filter((s) => s.name && s.value).length > 1 ? "&" : ""}`;
            // let lastChar = stringRequest.substr(stringRequest.length - 1);
            // if(lastChar === "&") {
            //   stringRequest.slice(-1);
            // }
          }
        });
      }
    }
    response = await fetchWithTimeout(stringRequest, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
    });

    const responseJSON = await response.json();
    
    if (responseJSON.code === 200 || responseJSON.statusCode === 200 || response.status === 200) {
      if(responseJSON.message) {
        toast.success(i18n.t(responseJSON.message));
      }
      return responseJSON;
    } else {
      toast.error(i18n.t(responseJSON.message));
      // throw new Error(`HTTP error! status: ${response.status}`);
    }

  } catch (e) {
    // Erros podem ser do Axios ou outros. Caso sejam erros http 4xx (401, 402, etc)
    // é gerado um ClientError. Caso sejam erros http 5xx (500, 501, etc) é lançado
    // um erro ServerError. Caso sejam outros tipos de erros http ou erros de outra natureza,
    // o erro é relançado para a função que chamou.
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status;

      if (statusCode?.toString() === "401") {
        // localStorage.removeItem(process.env.NEXT_PUBLIC_IS_LOGGED as string);
        // localStorage.setItem(process.env.NEXT_PUBLIC_IS_LOGGED as string, 'false');
        // localStorage.removeItem(process.env.NEXT_PUBLIC_APP_TOKEN_KEY as string);
        // throw new (ClientError as any)("Tente logar novamente");
        toast.error(i18n.t('Sessão inválida ou expirada.'));
      }

      if (
        statusCode?.toString() !== "401" &&
        statusCode?.toString().startsWith("4")
      )

        toast.error('Erro na validação da regra de negócio');
      // throw new (ClientError as any)(
      //   `Erro na validação da regra de negócio! (${e.response?.data.data})`
      // );

      if (statusCode?.toString().startsWith("5"))
        toast.error(i18n.t('Servidor indisponível.'));
      // throw new (ServerError as any)("Servidor indisponível.");
    }
    console.log(e);
    toast.error(i18n.t('Ocorreu um erro interno ao tentar se conectar com o servidor.'));
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get,
  save,
  savePut,
  savePatch,
  remove,
  saveFormData,
  getById,
  saveLivechat,
  replaceGetById,
  saveWithId,
  putWithId,
  getByIdWithParams,
  getByIdWithParamsType,
  putWithTwoId,
  putByIdWithParamsType,
  postByIdWithParamsType
};