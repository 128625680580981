import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
    PDFViewer,
    Font,
} from "@react-pdf/renderer";
import { TPaagTransaction } from "src/@types/payments";
import paag_logo from '../../assets/images/paag_logo.png';
import { useTranslation } from "react-i18next";
import FontPoppinsRegular from '../../assets/fonts/Poppins/Poppins-Regular.ttf';
import FontPoppinsMedium from '../../assets/fonts/Poppins/Poppins-Medium.ttf';
import FontPoppinsBold from '../../assets/fonts/Poppins/Poppins-Bold.ttf';
import FontPoppinsExtraBold from '../../assets/fonts/Poppins/Poppins-ExtraBold.ttf';
import FontPoppinsLight from '../../assets/fonts/Poppins/Poppins-Light.ttf';
import moment from "moment";
import { useEffect, useState } from "react";
import PaymentMethod from "src/infraestructure/services/api/PaymentMethod";
import { Spinner } from "@radix-ui/themes";

type PaagPDFViewProps = {
    transactionId: string;
    type: string;
    transactionData?: TPaagTransaction;
    maxWidth?: number;
    isRefunded?: boolean;
}

Font.register({
    family: 'Poppins',
    fonts: [
        {
            src: FontPoppinsRegular,
        },
        {
            src: FontPoppinsMedium,
            fontWeight: 'medium',
        },
        {
            src: FontPoppinsBold,
            fontWeight: 'bold',
        },
        {
            src: FontPoppinsRegular,
            fontWeight: 'normal',
        },
        {
            src: FontPoppinsLight,
            fontWeight: 'light',
        },
        {
            src: FontPoppinsExtraBold,
            fontWeight: 'ultrabold'
        }
    ],
});

const PaagPDFViewer = ({ transactionId, maxWidth, type, isRefunded }: PaagPDFViewProps) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<TPaagTransaction>();

    const { t } = useTranslation();

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            color: '#000',
            padding: 40,
            height: '100%'
        },
        image: {
            width: 100,
            height: 30
        }
    });

    useEffect(() => {
        getTransactionById();
    }, [transactionId]);

    const getTransactionById = async () => {
        setLoading(true);
        if (type === "deposits") {
            const getById = await PaymentMethod.getByIdDeposits({
                typeId: 'paag',
                transactionId: transactionId
            });
            if (getById && getById.data) {
                setData(getById.data);
            }
        } else {
            const getById = await PaymentMethod.getByIdWithDrawals({
                typeId: 'paag',
                transactionId: transactionId
            });
            console.log(getById);
            if (getById && getById.data) {
                setData(getById.data);
            }
        }
        setLoading(false);
    }


    if (!loading) {
        if (!isRefunded) {
            if (type === "deposits") {
                return (
                    <PDFViewer style={{ height: 1000 }}>
                        <Document>
                            <Page size={"A4"} style={styles.page}>
                                <Image src={paag_logo} style={styles.image} />
                                <Text style={{ marginTop: 25, fontSize: 18, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comprovante de Transferência</Text>
                                <Text style={{ fontSize: 10, marginTop: 3, color: '#494949', fontFamily: 'Poppins', fontWeight: 'light' }}>Realizada em {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}</Text>

                                {data?.error && <Text style={{ marginTop: 25, fontSize: 11, fontFamily: 'Poppins', fontWeight: 'medium' }}>Motivo do Erro:</Text>}
                                {data?.error && <Text style={{ color: 'red', fontSize: 12, fontFamily: 'Poppins', fontWeight: 'bold' }}>{t(data?.error.message)}</Text>}

                                <Text style={{ marginTop: 25, fontSize: 12, fontFamily: 'Poppins', fontWeight: 'medium' }}>Valor</Text>
                                <Text style={{ color: '#1d6d8d', fontSize: 15, fontFamily: 'Poppins', fontWeight: 'bold' }}>R$ {data?.transaction_events[0].amount}</Text>

                                {data?.statement && data?.statement.length > 0 && <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA ORIGEM</Text>}

                                {data?.statement && data?.statement.length > 0 && <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />}

                                {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.name : ""}</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CPF</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.document : ""}</Text>
                                    </div>
                                </div>}

                                {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_name : ""}</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_agency : ""}</Text>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.acc_number : ""}</Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA DESTINO</Text>
                                <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CNPJ</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>REPASSES E INTERMEDIACOES (560)</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>0001</Text>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta Corrente</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>352466429</Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comerciante</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.processor.alias}</Text>
                                    </div>
                                </div>


                                <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>INFORMAÇÕES DA TRANSFERÊNCIA</Text>
                                <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>E2E ID da transação</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.e2e_id}</Text>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Tipo</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.transaction_type}</Text>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 25 }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                                </div>

                            </Page>
                        </Document>
                    </PDFViewer>
                );
            } else {
                return (
                    <PDFViewer style={{ height: 1000 }}>
                        <Document>
                            <Page size={"A4"} style={styles.page}>
                                <Image src={paag_logo} style={styles.image} />
                                <Text style={{ marginTop: 25, fontSize: 18, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comprovante de Transferência</Text>
                                <Text style={{ fontSize: 10, marginTop: 3, color: '#494949', fontFamily: 'Poppins', fontWeight: 'light' }}>Realizada em {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}</Text>

                                {data?.error && <Text style={{ marginTop: 25, fontSize: 11, fontFamily: 'Poppins', fontWeight: 'medium' }}>Motivo do Erro:</Text>}
                                {data?.error && <Text style={{ color: 'red', fontSize: 12, fontFamily: 'Poppins', fontWeight: 'bold' }}>{t(data?.error.message)}</Text>}

                                <Text style={{ marginTop: 25, fontSize: 12, fontFamily: 'Poppins', fontWeight: 'medium' }}>Valor</Text>
                                <Text style={{ color: '#1d6d8d', fontSize: 15, fontFamily: 'Poppins', fontWeight: 'bold' }}>R$ {data?.transaction_events[0].amount}</Text>


                                <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA ORIGEM</Text>
                                <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CNPJ</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>REPASSES E INTERMEDIACOES (560)</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>0001</Text>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta Corrente</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>352466429</Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comerciante</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.processor.alias}</Text>
                                    </div>
                                </div>

                                {data?.statement && data?.statement.length > 0 && <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA DESTINO</Text>}

                                {data?.statement && data?.statement.length > 0 && <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />}

                                {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.name : ""}</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CPF</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.document : ""}</Text>
                                    </div>
                                </div>}

                                {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_name : ""}</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_agency : ""}</Text>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta</Text>
                                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.acc_number : ""}</Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>}


                                <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>INFORMAÇÕES DA TRANSFERÊNCIA</Text>
                                <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>E2E ID da transação</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.e2e_id}</Text>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Tipo</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.transaction_type}</Text>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 25 }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                                </div>

                            </Page>
                        </Document>
                    </PDFViewer>
                )
            }
        } else if (!isRefunded || type === "withdraw") {
            return (
                <PDFViewer style={{ height: 1000 }}>
                    <Document>
                        <Page size={"A4"} style={styles.page}>
                            <Image src={paag_logo} style={styles.image} />
                            <Text style={{ marginTop: 25, fontSize: 18, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comprovante de Reembolso</Text>
                            <Text style={{ fontSize: 10, marginTop: 3, color: '#494949', fontFamily: 'Poppins', fontWeight: 'light' }}>Realizada em {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}</Text>

                            {data?.error && <Text style={{ marginTop: 25, fontSize: 11, fontFamily: 'Poppins', fontWeight: 'medium' }}>Motivo do Erro:</Text>}
                            {data?.error && <Text style={{ color: 'red', fontSize: 12, fontFamily: 'Poppins', fontWeight: 'bold' }}>{t(data?.error.message)}</Text>}

                            <Text style={{ marginTop: 25, fontSize: 12, fontFamily: 'Poppins', fontWeight: 'medium' }}>Valor</Text>
                            <Text style={{ color: '#1d6d8d', fontSize: 15, fontFamily: 'Poppins', fontWeight: 'bold' }}>R$ {data?.transaction_events[0].amount}</Text>


                            <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA ORIGEM</Text>
                            <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CNPJ</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>REPASSES E INTERMEDIACOES (560)</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>0001</Text>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta Corrente</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>352466429</Text>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comerciante</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.processor.alias}</Text>
                                </div>
                            </div>

                            {data?.statement && data?.statement.length > 0 && <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA DESTINO</Text>}

                            {data?.statement && data?.statement.length > 0 && <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />}

                            {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.name : ""}</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CPF</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.document : ""}</Text>
                                </div>
                            </div>}

                            {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_name : ""}</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_agency : ""}</Text>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.acc_number : ""}</Text>
                                        </div>
                                    </div>
                                </div>
                            </div>}


                            <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>INFORMAÇÕES DA TRANSFERÊNCIA</Text>
                            <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>E2E ID da transação</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.e2e_id}</Text>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Tipo</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.transaction_type}</Text>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 25 }}>
                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                            </div>

                        </Page>
                    </Document>
                </PDFViewer>
            )
        } else {
            return (
                <PDFViewer style={{ height: 1000 }}>
                    <Document>
                        <Page size={"A4"} style={styles.page}>
                            <Image src={paag_logo} style={styles.image} />
                            <Text style={{ marginTop: 25, fontSize: 18, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comprovante de Transferência</Text>
                            <Text style={{ fontSize: 10, marginTop: 3, color: '#494949', fontFamily: 'Poppins', fontWeight: 'light' }}>Realizada em {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}</Text>

                            {data?.error && <Text style={{ marginTop: 25, fontSize: 11, fontFamily: 'Poppins', fontWeight: 'medium' }}>Motivo do Erro:</Text>}
                            {data?.error && <Text style={{ color: 'red', fontSize: 12, fontFamily: 'Poppins', fontWeight: 'bold' }}>{t(data?.error.message)}</Text>}

                            <Text style={{ marginTop: 25, fontSize: 12, fontFamily: 'Poppins', fontWeight: 'medium' }}>Valor</Text>
                            <Text style={{ color: '#1d6d8d', fontSize: 15, fontFamily: 'Poppins', fontWeight: 'bold' }}>R$ {data?.transaction_events[0].amount}</Text>

                            {data?.statement && data?.statement.length > 0 && <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA ORIGEM</Text>}

                            {data?.statement && data?.statement.length > 0 && <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />}

                            {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.name : ""}</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CPF</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.document : ""}</Text>
                                </div>
                            </div>}

                            {data?.statement && data?.statement.length > 0 && <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_name : ""}</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.bank_agency : ""}</Text>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.statement && data?.statement.length > 0 ? data?.statement[0].payer.acc_number : ""}</Text>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>CONTA DESTINO</Text>
                            <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Nome</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>CNPJ</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Banco</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>REPASSES E INTERMEDIACOES (560)</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Agência</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>0001</Text>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Conta Corrente</Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>352466429</Text>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Comerciante</Text>
                                    <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.processor.alias}</Text>
                                </div>
                            </div>


                            <Text style={{ marginTop: 25, fontSize: 15, fontWeight: 'bold', fontFamily: 'Poppins' }}>INFORMAÇÕES DA TRANSFERÊNCIA</Text>
                            <div style={{ width: '100%', borderBottom: '1px dotted #797979', marginTop: 5 }} />

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>E2E ID da transação</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.e2e_id}</Text>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Tipo</Text>
                                        <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>{data?.transaction_type}</Text>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 25 }}>
                                <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Poppins' }}>Servicos e Intermediacoes Paag LTDA</Text>
                                <Text style={{ fontSize: 11, fontWeight: 'light', fontFamily: 'Poppins' }}>41.388.405/0001-26</Text>
                            </div>

                        </Page>
                    </Document>
                </PDFViewer>
            )
        }
    } else {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner size={"3"} />
            </div>
        );
    }
}

export default PaagPDFViewer;