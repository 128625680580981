import { TDepositsRequest } from "src/@types/deposits";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/:id/logins";

const getById = async (data: TDepositsRequest) => GenericDAO.getByIdWithParams(route, parseInt(data.clientId), [
    {
        name: "clientId",
        value: data.clientId as any,
    },
    {
        name: "startDate",
        value: data.startDate as any,
    },
    {
        name: "endDate",
        value: data.endDate as any,
    },
    {
        name: "typeId",
        value: data.typeId as any,
    },
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "pageSize",
        value: data.pageSize as any,
    }
], true);

export default {
    getById
};