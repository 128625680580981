
// import { Container } from './styles';

import { Avatar, Badge, Box, Card, Code, DataList, Flex, Grid, IconButton, Link, SegmentedControl, Skeleton, Strong, Text } from "@radix-ui/themes";
import { ArrowLeftCircleIcon, CopyIcon, MapIcon } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { TAffiliatesInfo } from "src/@types/affiliates";
import { EBonusTabs } from "src/@types/bonus";
import AffiliatesStatistics from "src/components/AffiliatesStatistics";
import AffiliatesWithdrawals from "src/components/AffiliatesWithdrawals";
import MainLayout from "src/components/MainLayout";
import { CardDescription, CardTitle } from "src/components/ui/card";
import Affiliates from "src/infraestructure/services/api/Affiliates";
import userProfileStyles from "src/styles/components/userProfileStyles";
import { copyToClipBoard, formatCurrency } from "src/utils";

const AffiliatesUser = () => {

    const { CardUserInfoContainer, ContainerCityInfo, CardWrapper, BackButtonStyle } = userProfileStyles();

    const [affiliateInfo, setAffiliateInfo] = useState<TAffiliatesInfo>();
    const [loadingClientInfo, setLoadingClientInfo] = useState<boolean>(true);
    const [tabSelect, setTabSelect] = useState<EBonusTabs>(EBonusTabs.statistics);

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams && searchParams.get('tab')) {
            setTabSelect(searchParams.get('tab')?.toLowerCase() as EBonusTabs);
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        if (id) {
            const aff = await Affiliates.get({
                email: id
            });

            if (aff && aff.data) {
                setAffiliateInfo(aff.data);
            }
            setLoadingClientInfo(false);
        }
    }

    return (
        <MainLayout>
            <div className={BackButtonStyle} onClick={() => {
                navigate('/affiliates/list');
            }}>
                <ArrowLeftCircleIcon />
                <Text>{t('Voltar')}</Text>
            </div>
            <Grid columns=".3fr 1fr" gap="5" rows="repeat(1, auto)" width="auto">
                {loadingClientInfo &&
                    <Skeleton height={'auto'} width={'auto'} style={{ borderRadius: 10 }} />
                }
                {affiliateInfo && !loadingClientInfo && <Card className={CardUserInfoContainer}>
                    {affiliateInfo && affiliateInfo.name && <Avatar radius="full" fallback={affiliateInfo?.name[0].toUpperCase() as string} size={"8"} />}
                    <Text size={"4"}><Strong>{affiliateInfo?.name}</Strong></Text>
                    <div className={ContainerCityInfo}>
                        <MapIcon></MapIcon>
                        <Text as="p">{affiliateInfo?.city}</Text>
                    </div>
                    <DataList.Root mt={"20px"}>
                        <DataList.Item align="center">
                            <DataList.Label minWidth="88px">Plano</DataList.Label>
                            <DataList.Value>
                                <Badge color="jade" variant="soft" radius="full">
                                    {affiliateInfo.planName}
                                </Badge>
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item>
                            <DataList.Label minWidth="88px">ID</DataList.Label>
                            <DataList.Value>
                                <Flex align="center" gap="2">
                                    <Code variant="ghost">{affiliateInfo?.affiliateId}</Code>
                                    <IconButton
                                        size="1"
                                        aria-label="Copy value"
                                        color="gray"
                                        onClick={() => {
                                            if (affiliateInfo.affiliateId) {
                                                copyToClipBoard(String(affiliateInfo.affiliateId));
                                                toast.success(`Copiado com sucesso!`)
                                            }
                                        }}
                                        variant="ghost"
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Flex>
                            </DataList.Value>
                        </DataList.Item>
                        {/* <DataList.Item>
                            <DataList.Label minWidth="88px">Gênero</DataList.Label>
                            <DataList.Value>{affiliateInfo?.gender}</DataList.Value>
                        </DataList.Item> */}
                        <DataList.Item>
                            <DataList.Label minWidth="88px">Login</DataList.Label>
                            <DataList.Value>
                                {affiliateInfo?.email}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item>
                            <DataList.Label minWidth="88px">Último Login</DataList.Label>
                            <DataList.Value>
                                <Badge>
                                    {affiliateInfo.lastLogin}
                                    {/* {clientInfo?.sportsbookProfile} */}
                                </Badge>
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                            <DataList.Label minWidth="88px">Status</DataList.Label>
                            <DataList.Value>
                                <Badge color={affiliateInfo?.status === "Active" ? "green" : "red"}>
                                    {affiliateInfo.status}
                                </Badge>
                            </DataList.Value>
                        </DataList.Item>

                    </DataList.Root>
                    <Flex align={"center"} mt={"10px"} gap={"10px"}>
                        {/* {!loadingResetPassword && <Button onClick={handleResetPassword} variant="classic">{t('Reset_Password')}</Button>}
                        {loadingResetPassword && <Button disabled variant="classic">
                            <Spinner loading>
                                <BookmarkIcon />
                            </Spinner>
                            {t('Reset_Password')}
                        </Button>}
                        {id && <ViewGGR clientId={id} />} */}
                        {/* <Button onClick={handleResetPassword} variant="classic" color="green">{t('Consult_GGR')}</Button> */}
                    </Flex>
                </Card>}
                <Box>
                    <Grid columns="4" gap="5" rows="repeat(1, auto)" width="auto">
                        {loadingClientInfo &&
                            <>
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                            </>
                        }
                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Plan')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{affiliateInfo?.planName}</Text></CardDescription>
                            {/* <img src={piggy_bank} className={ImgWrapper} /> */}
                        </Card>}

                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Register_Date')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{affiliateInfo?.registerDate}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Players_Count')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{affiliateInfo?.playersCount}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Last_Login')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{affiliateInfo?.lastLogin}</Text></CardDescription>
                        </Card>}

                        {/* {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Comission_Plan')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{affiliateInfo?.comissionPlan}</Text></CardDescription>
                        </Card>} */}

                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Verified')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{t(affiliateInfo?.affiliateDetails.verified)}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Available_Wallet')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{formatCurrency(parseFloat(affiliateInfo?.availableWallet))}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Pending_CPA')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{formatCurrency(parseFloat(affiliateInfo?.affiliateDetails.currentCpaWallet.replace("R$", "")))}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && affiliateInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Pending_Withdraw')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{affiliateInfo?.affiliateDetails.withdrawAmountPerBrand.length > 0 ?  formatCurrency(parseFloat(affiliateInfo?.affiliateDetails.withdrawAmountPerBrand[Object.keys(affiliateInfo?.affiliateDetails?.withdrawAmountPerBrand)[0]].withdrawAmount)) : ""}</Text></CardDescription>
                        </Card>}

                    </Grid>
                    {loadingClientInfo && <Skeleton height={'400px'} width={'auto'} mt={"20px"} style={{ borderRadius: 10 }} />}
                    {!loadingClientInfo && affiliateInfo && <Card mt={"20px"} className={CardWrapper}>
                        <CardTitle>{t('Info')}</CardTitle>
                        <SegmentedControl.Root defaultValue={tabSelect} style={{ width: 500 }} onValueChange={(e: EBonusTabs) => setTabSelect(e)}>
                            <SegmentedControl.Item value={EBonusTabs.statistics}>Estatísticas</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.withdrawals}>Saques</SegmentedControl.Item>
                        </SegmentedControl.Root>

                        <Box mt={'20px'}>
                            {tabSelect === EBonusTabs.statistics &&
                                <AffiliatesStatistics id={affiliateInfo.affiliateId} />
                            }
                            {tabSelect === EBonusTabs.withdrawals &&
                                <AffiliatesWithdrawals id={affiliateInfo.affiliateId} />
                            }
                        </Box>
                    </Card>}

                </Box>
            </Grid>
        </MainLayout>
    );
}

export default AffiliatesUser;