import { Badge, Button, Flex, Table } from '@radix-ui/themes';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { CircleMinus, CirclePlus } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { TBetSelectionResponse } from 'src/@types/bet';
import { IReportByBet } from 'src/@types/reports';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import TablePaginated from 'src/components/TablePaginated';
import BetSelections from 'src/infraestructure/services/api/BetSelections';
import Reports from 'src/infraestructure/services/api/Reports';
import { getColorFromStatus } from 'src/utils';

const ReportByBetSportbook: React.FC = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<IReportByBet[]>([]);
    const [page, setPage] = useState<number>(1);
    const [filters, setFilteres] = useState<any>();
    const [betSelection, setBetSelection] = useState<TBetSelectionResponse[]>();
    const [selectedBet, setSelectedBet] = useState<boolean>(false);

    const handleFilter = async (data: any) => {
        setLoading(true);
        const reportByBet = await Reports.getReportBet({
            betId: data.betId,
            page: 1,
            limit: 25,
            startDate: data.startDate,
            endDate: data.endDate
        });

        if (reportByBet && reportByBet.data) {
            setBetSelection([]);
            setFilteres({
                betId: data.betId,
                limit: 25,
                startDate: data.startDate,
                endDate: data.endDate
            });

            setList(reportByBet.data);
        }

        setLoading(false);
    }

    const backPage = async () => {
        let curPage = page - 1;
        const reportByBet = await Reports.getReportBet({
            betId: filters.betId,
            page: curPage,
            limit: 25,
            startDate: filters.startDate,
            endDate: filters.endDate
        });

        if (reportByBet && reportByBet.data) {
            setList(reportByBet.data);
            setPage(curPage);
        }
    }

    const nextPage = async () => {
        let curPage = page + 1;
        const reportByBet = await Reports.getReportBet({
            betId: filters.betId,
            page: curPage,
            limit: 25,
            startDate: filters.startDate,
            endDate: filters.endDate
        });

        if (reportByBet && reportByBet.data) {
            if (reportByBet.data.length > 0) {
                setList(reportByBet.data);
                setPage(curPage);
            } else {
                toast.error(t(`No results found.`));
            }
        }
    }

    const handleBetSelection = async (betId: string) => {
        const getSelections = await BetSelections.getBetSelections({
            betId: betId,
            type: "2"
        })

        if (getSelections && getSelections.data) {
            setBetSelection(getSelections.data);
        }
    }

    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "search",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "betId",
                        options: [
                            {
                                label: "ID da Aposta",
                                value: "betId",
                            }
                        ],
                    },
                    {
                        name: "startDate,endDate",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.range,
                        predefinedValue: [startOfDay(new Date()), endOfDay(addDays(new Date(), +1))],
                        format: 'DD-MM-YY'
                    }
                ]}
                loading={loading}
                onPress={handleFilter}
            />

            <TablePaginated
                data={list}
                totalItems={0}
                childrenTable={() => {
                    if (selectedBet) {
                        return (
                            <>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColumnHeaderCell>{t(`StartTimeLocal`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`BetId`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`SportName`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`CompetitionName`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`MatchName`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`MarketName`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`DisplaySelectionName`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`Price`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`StateName`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`MatchInfo`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`MatchResult`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t(`EndTimeLocal`)}</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>{t('VoidReason')}</Table.ColumnHeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {betSelection?.map((i, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    {moment(i.StartTimeLocal).format("DD/MM/YYYY HH:mm")}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.BetId}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.SportName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.CompetitionName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.MatchName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.MarketName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.DisplaySelectionName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.Price}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Badge color={getColorFromStatus(t(i.StateName))}>{t(i.StateName)}</Badge>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.MatchInfo}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.MatchResult}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {moment(i.EndTimeLocal).format("DD/MM/YYYY HH:mm")}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {i.VoidReason}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </>
                        )
                    } else {
                        return null;
                    }
                }}
                keys={[
                    {
                        name: "Actions",
                        type: "string",
                        children: ((response) => {
                            return (
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                    setSelectedBet(!selectedBet);
                                    handleBetSelection(response.Id);
                                }}>
                                    {selectedBet ? <CircleMinus /> : <CirclePlus />}
                                </div>
                            )
                        })
                    },
                    {
                        name: "Id",
                        type: "string"
                    },
                    {
                        name: "ClientId",
                        type: "number"
                    },
                    {
                        name: "Amount",
                        type: "money"
                    },
                    {
                        name: "Price",
                        type: "number"
                    },
                    {
                        name: "WinningAmount",
                        type: "money"
                    },
                    {
                        name: "TypeName",
                        type: "string"
                    },
                    {
                        name: "StateName",
                        type: "tag"
                    },
                    {
                        name: "ClientFirstName",
                        type: "string"
                    },
                    {
                        name: "CreatedLocal",
                        type: "date"
                    },
                    {
                        name: "CalcDateLocal",
                        type: "date"
                    },
                    {
                        name: "ClientLogin",
                        type: "string"
                    },
                    {
                        name: "ClientLastName",
                        type: "string"
                    },
                ]}
                perPage={0}
                onPageChange={function (page: number): void {
                    throw new Error('Function not implemented.');
                }}
            />
            {filters && list.length > 25 && <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}>
                {page !== 1 && <Button variant='soft' onClick={() => {
                    backPage();
                }}>Anterior</Button>}
                <div>{page}</div>
                <Button variant='soft' onClick={() => {
                    nextPage();
                }}>Próximo</Button>
            </Flex>}
        </MainLayout>
    );
}

export default ReportByBetSportbook;