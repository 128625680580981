import { Avatar, Badge, Box, Card, Flex, Table, Text } from '@radix-ui/themes';
import { EyeIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TAffiliatesInfo } from 'src/@types/affiliates';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import Affiliates from 'src/infraestructure/services/api/Affiliates';

const AffiliatesList = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [affiliates, setAffiliates] = useState<TAffiliatesInfo>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    const handleFilter = async (data: any) => {
        setLoading(true);
        const affiliates = await Affiliates.get({
            email: data.email
        })
        if(affiliates && affiliates.data) {
            setAffiliates(affiliates.data);
        }
        setLoading(false);
    }


    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "Email",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "email",
                        options: [
                            {
                                label: "Email",
                                value: "email",
                            }
                        ],
                    }
                ]}
                onPress={handleFilter}
                loading={loading}
            />
            <Table.Root variant="surface" mt={'20px'}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Sobrenome</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Data de Registro</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Plano de Comissão</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Tipo de Plano</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Qtde Jogadores</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Ações</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>

                {affiliates &&
                    <Table.Body>
                        <Table.Row>
                            <Table.RowHeaderCell>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}><EyeIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    navigate(`/affiliates/${affiliates.email}`);
                                }} />{affiliates.affiliateId}</div></Table.RowHeaderCell>
                            <Table.Cell>{affiliates.email}</Table.Cell>
                            <Table.Cell>{affiliates.name}</Table.Cell>
                            <Table.Cell>{affiliates.lastName}</Table.Cell>
                            <Table.Cell>{affiliates.registerDate}</Table.Cell>
                            <Table.Cell>{affiliates.planName}</Table.Cell>
                            <Table.Cell><Badge>{affiliates.hybridId ? "Hibrído" : "CPA"}</Badge></Table.Cell>
                            <Table.Cell>{affiliates.playersCount}</Table.Cell>
                            <Table.Cell>

                            </Table.Cell>
                        </Table.Row>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                Comissão Pendente
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                R${affiliates?.currentWallet}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>

                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                CPA Pendente
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                R${affiliates?.affiliateDetails?.currentCpaWallet}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>

                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                Comissão Disponível
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                R${affiliates?.affiliateDetails?.availableWallet}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>

                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                Saque Pendente
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                {affiliates?.affiliateDetails.withdrawAmountPerBrand.length > 0 ? `R$ ${affiliates?.affiliateDetails?.withdrawAmountPerBrand[Object.keys(affiliates?.affiliateDetails?.withdrawAmountPerBrand)[0]].withdrawAmount}` : ""}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>
                        </div>
                    </Table.Body>
                }
            </Table.Root>
        </MainLayout>
    );
}

export default AffiliatesList;