import { Box, Button, Flex, Grid, IconButton, Popover, Select, Spinner, TextField } from '@radix-ui/themes';
import { EditIcon } from 'lucide-react';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { TClientEditRequest } from 'src/@types/clients';
import Clients from 'src/infraestructure/services/api/Clients';

type EditUserNameProps = {
    userId: string;
    firstName: string;
    lastName: string;
    onFinish: (data: any, firstValue: string, secondValue: string) => void;
}

type EditUserNameForm = {
    firstName: string;
    lastName: string;
}

const EditUserName = ({ userId, firstName, lastName, onFinish }: EditUserNameProps) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<EditUserNameForm>()
    const onSubmit: SubmitHandler<EditUserNameForm> = async (data) => {
        setLoading(true);

        const request = await Clients.edit(userId, data);
        if (request && request.data) {
            onFinish(request.data, "firstName", "lastName");
            toast.success(`Dados alterados com sucesso!`);
        }
        setLoading(false);
        setOpen(false);
    }


    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger>
                <IconButton radius='full'>
                    <EditIcon size={"18"} />
                </IconButton>
            </Popover.Trigger>
            <Popover.Content width="360px">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex gap="3">
                        <Box flexGrow="1">
                            <Grid columns={"2"} gap={"2"}> 
                                <TextField.Root defaultValue={firstName} {...register('firstName', {
                                    required: true
                                })} placeholder={`Digite o primeiro nome`} />

                                <TextField.Root defaultValue={lastName} {...register('lastName', {
                                    required: true
                                })} placeholder={`Digite o ultimo nome`} />
                            </Grid>
                            {errors.firstName && <div style={{ color: 'red' }}>{t('FirstName_Required')}</div>}
                            {errors.lastName && <div style={{ color: 'red' }}>{t('LastName_Required')}</div>}

                            <Flex gap="3" mt="3" justify="between">
                                {!loading && <Button type='submit' size="1">{t('Save')}</Button>}
                                {loading && <Button size="1"><Spinner size={"1"} /></Button>}
                            </Flex>
                        </Box>
                    </Flex>
                </form>
            </Popover.Content>
        </Popover.Root>
    );
}

export default EditUserName;