import { TLoginFormRequest, TUserById } from "src/@types/auth";
import GenericDAO from "../GenericDAO";

const route = "/v1/auth/login";
const routeByUser = "/v1/users";
const routeGetUser = "/v1/users/me";

const save = async (data: TLoginFormRequest) => GenericDAO.save(route, data);
const getById = async (data: TUserById) => GenericDAO.getById(routeByUser, data.userId, true);
const getMe = async () => GenericDAO.get(routeGetUser, undefined, undefined, true);

export const isLogged = (): boolean => localStorage.getItem("@App:token") !== null;

const getToken = () => {
  if (isLogged()) {
    return localStorage.getItem("@App:token");
  }
  return undefined;
//   throw new (ClientError as any)("Usuário não está logado.");
};

export default {
  save,
  getToken,
  getById,
  getMe
//   get,
//   logout
};