import React from 'react';

// import { Container } from './styles';

const Footer = () => {
  return (
    <div style={{ position: 'absolute', bottom: 0, justifyContent: 'center', display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0 }}>
        <p style={{ textAlign: 'center', color: '#f8f8f8', fontSize: 12 }}>OIG DASHBOARD ©️ 2024 ❤️</p>
    </div>
  );
}

export default Footer;