import { TClientEditRequest, TClientsSearchRequest } from "src/@types/clients";
import GenericDAO from "../GenericDAO";

const route = "/v1/clients/search";
const routeEdit = "/v1/clients/:id/edit";

const edit = async (clientId: string, data: TClientEditRequest) => GenericDAO.putWithId(routeEdit, { clientId: clientId }, data, true);

const get = async (data: TClientsSearchRequest) => GenericDAO.get(route, null, [
    {
        name: "clientId",
        value: data.clientId as any,
    },
    {
        name: "personalId",
        value: data.personalId as any,
    },
    {
        name: "email",
        value: data.email as any,
    },
    {
        name: "phone",
        value: data.phone as any,
    }
], true);

export default {
    get,
    edit
};