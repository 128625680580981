import { Badge, Button, Dialog, Flex } from "@radix-ui/themes";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getColorFromStatus } from "src/utils";

export type TModalLogsProps = {
    status: string;
    amount: number;
    logList: {
        date: string;
        info: string;
        status: string;
    }[];
}

const ModalLogs = ({ status, logList, amount }: TModalLogsProps) => {

    const { t } = useTranslation();

    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <Badge color={getColorFromStatus(t(status))}>{t(status)}</Badge>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px">
                <Dialog.Title>{t('View_Logs')}</Dialog.Title>
                <Dialog.Description size="2" mb="4">

                </Dialog.Description>

                {logList.map((i, index) => {
                    return (
                        <ul key={index}>
                            <li>{moment(i.date).format("DD/MM/YYYY HH:mm:ss")}</li>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontWeight: 'bold' }}>{t(i.status)}</div>
                                {i.info !== "" &&
                                    <div style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{t(i.info)}</div>
                                }
                            </div>
                            {i.status === "Reversed" &&
                                <div style={{ fontWeight: 'bold' }}>Devolução TOTAL de: R$ {amount.toFixed(2)}</div>
                            }
                        </ul>
                    )
                })}


                <Flex gap="3" mt="4" justify="end">
                    <Dialog.Close>
                        <Button variant="soft" color="gray">
                            Fechar
                        </Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>

    );
}

export default ModalLogs;