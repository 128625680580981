import { TCreateUserRequest } from "src/@types/user";
import GenericDAO from "../../GenericDAO";

const route = "/v1/users";
const routeCreate = "/v1/users/create-user";
const routeEdit = "/v1/users/:id/edit";
const routeDelete = "/v1/users/:id";

const get = async (data: {
    page: number;
    limit: number;
}) => GenericDAO.get(route, null, [
    {
        name: 'page',
        value: data.page as any
    },
    {
        name: 'limit',
        value: data.limit as any
    }
], true);
const save = async (data: TCreateUserRequest) => GenericDAO.save(routeCreate, data, true, 30000);
const remove = async (id: string ) => GenericDAO.remove(routeDelete, id, true);
const edit = async (id: string, data: TCreateUserRequest) => GenericDAO.putWithId(routeEdit, {
    clientId: id
}, data, true, 30000);

export default {
    get,
    save,
    edit,
    remove
};