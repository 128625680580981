import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Filter, { FTypes } from '../Filter';
import { TAffiliatesStatisticsFilter, TAffiliatesStatisticsResponse } from 'src/@types/affiliates';
import Affiliates from 'src/infraestructure/services/api/Affiliates';
import { TAffProps } from '../AffiliatesWithdrawals';
import { Button, Flex, Table } from '@radix-ui/themes';
import moment from 'moment';
import { endOfDay, startOfDay, subDays } from 'date-fns';

const AffiliatesStatistics = ({ id }: TAffProps) => {

    const { t } = useTranslation();
    const [list, setList] = useState<TAffiliatesStatisticsResponse[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [saveFilters, setSaveFilters] = useState<any>();

    const handleFilter = async (data: TAffiliatesStatisticsFilter) => {
        setLoading(true);
        const request = await Affiliates.getStatistics({
            affiliateId: id,
            startDate: data.startDate,
            endDate: data.endDate,
            page: 1,
            perPage: 25
        });

        setSaveFilters({
            affiliateId: id,
            startDate: data.startDate,
            endDate: data.endDate,
        });

        if (request && request.data) {
            setList(request.data);
            setPage(1);
        }

        setLoading(false);
    }

    useEffect(() => {
        handleFilter({
            startDate: moment(startOfDay(subDays(new Date(), 27))).format("YYYY/MM/DD"),
            endDate: moment(endOfDay(new Date())).format("YYYY/MM/DD")
        })
    }, []);

    const backPage = async () => {
        let curPage = page - 1;
        const request = await Affiliates.getStatistics({
            affiliateId: id,
            startDate: saveFilters.startDate,
            endDate: saveFilters.endDate,
            page: curPage,
            perPage: 25
        });

        if (request && request.data) {
            setList(request.data);
        }
        setPage(curPage);
    }

    const nextPage = async () => {
        let curPage = page + 1;
        const request = await Affiliates.getStatistics({
            affiliateId: id,
            startDate: saveFilters.startDate,
            endDate: saveFilters.endDate,
            page: curPage,
            perPage: 25
        });

        if (request && request.data) {
            setList(request.data);
            setPage(curPage);
        }
    }


    return (
        <>
            <Filter showTitle={false} filters={[
                {
                    name: "startDate,endDate",
                    placeholder: t("Pesquisar por") as string,
                    type: FTypes.range,
                    predefinedValue: [
                        startOfDay(subDays(new Date(), 27)),
                        endOfDay(new Date()),
                    ],
                    format: 'YYYY/MM/DD'
                }
            ]} loading={loading} onPress={handleFilter} />

            <Table.Root variant='surface' mt={"4"}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>{t('PlayerId')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Date')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Deposits')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Withdrawals')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Bets')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('GGR')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('Comission')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>{t('CPA')}</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {list && list.length > 0 && list.map((i, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.RowHeaderCell>{i.playerId}</Table.RowHeaderCell>
                                <Table.Cell>{i.registerDate}</Table.Cell>
                                <Table.Cell>R$ {i.totalDeposits}</Table.Cell>
                                <Table.Cell>R$ {i.totalWithdrawals}</Table.Cell>
                                <Table.Cell>R$ {i.totalStake}</Table.Cell>
                                <Table.Cell>{i.casinoGroupGgr}</Table.Cell>
                                <Table.Cell>R$ {i.totalCommission}</Table.Cell>
                                <Table.Cell>R$ {i.playerCpaSum}</Table.Cell>
                            </Table.Row>
                        )
                    })
                    }
                </Table.Body>
            </Table.Root>
            <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}>
                {page !== 1 && <Button variant='soft' onClick={() => {
                    backPage();
                }}>Anterior</Button>}
                <div>{page}</div>
                <Button variant='soft' onClick={() => {
                    nextPage();
                }}>Próximo</Button>
            </Flex>
        </>
    );
}

export default AffiliatesStatistics;