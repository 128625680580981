import { jwtDecode } from "jwt-decode";

const useUserRole = () => {
    const userToken = window.localStorage.getItem("@App:user") ?? "";

    const jsonParse = JSON.parse(userToken);

    return jsonParse.role;
};

export default useUserRole;