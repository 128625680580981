import { createUseStyles } from 'react-jss';
import { color } from 'src/utils';

const userProfileStyles = createUseStyles({
    CardUserInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 10,
        padding: 40,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    },
    ContainerCityInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        marginBottom: 10
    },
    CardWithIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    CardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        borderWidth: 0,
        position: 'relative',
        paddingLeft: 30,
        '--inset-border-width': '0px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    },
    ImgWrapper: {
        position: 'absolute',
        right: 0,
        height: 60,
        width: 60,
        margin: 30
    },
    BackButtonStyle: {
        marginBottom: 20,
        display: 'flex',
        gap: 10,
        cursor: 'pointer'
    },
    Hlink: {
        textDecoration: 'underline',
        color: color('accent-a11'),
        cursor: 'pointer'
    }
});

export default userProfileStyles;