import React, { useState } from 'react';
import { TextField } from '@radix-ui/themes';

const CurrencyInput = ({ value, onChange }: any) => {
    const [inputValue, setInputValue] = useState(value || '');

    const formatCurrency = (value: any) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        const floatValue = parseFloat(numericValue) / 100;
        if (isNaN(floatValue)) return '';
        return floatValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    const handleInputChange = (e: any) => {
        const formattedValue = formatCurrency(e.target.value);
        setInputValue(formattedValue);
        onChange(formattedValue);
    };

    return (
        <TextField.Root
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="R$ 0,00">
        </TextField.Root>
    );
};

export default CurrencyInput;
