import { Button, Dialog, Flex, IconButton, Select, Spinner, Switch, Text, Tooltip } from "@radix-ui/themes";
import { Edit } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { PaymentSystemConfig } from "src/@types/admin";
import Config from "src/infraestructure/services/api/Admin/Config";
import useModalReceiptStyles from "src/styles/components/modalReceiptStyles";

type TModalPaymentConfigProps = {
    data: PaymentSystemConfig;
    paymentsList: PaymentSystemConfig[];
    paymentsCount: number;
    onFinish?: () => void;
}

interface TModalPaymentConfigForm extends PaymentSystemConfig {
}

const ModalPaymentConfig = (props: TModalPaymentConfigProps) => {

    const { t } = useTranslation();
    const { TextTitle, TextInfo } = useModalReceiptStyles();
    const [transactionInfo, setTransactionInfo] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm<TModalPaymentConfigForm>()
    const onSubmit: SubmitHandler<TModalPaymentConfigForm> = async (data) => {
        setLoading(true);
        let currentList = props.paymentsList;
        let indexEdited = currentList.findIndex((i) => i.payment_system === props.data.payment_system);

        if (data.order_deposit && !data.order_withdrawal) {
            let oldIndex = currentList.findIndex((i) => i.order_deposit === parseInt(data.order_deposit as any));
            if (Object.keys(currentList[oldIndex]).length > 0) {
                currentList[oldIndex] = {
                    payment_system: currentList[oldIndex].payment_system,
                    active_deposit: currentList[oldIndex].active_deposit,
                    active_withdrawal: currentList[oldIndex].active_withdrawal,
                    order_deposit: currentList[indexEdited].order_deposit,
                    order_withdrawal: currentList[oldIndex].order_withdrawal
                }
            }
        }

        if (!data.order_deposit && data.order_withdrawal) {
            let oldIndex = currentList.findIndex((i) => i.order_withdrawal === parseInt(data.order_withdrawal as any));
            if (Object.keys(currentList[oldIndex]).length > 0) {
                currentList[oldIndex] = {
                    payment_system: currentList[oldIndex].payment_system,
                    active_deposit: currentList[oldIndex].active_deposit,
                    active_withdrawal: currentList[oldIndex].active_withdrawal,
                    order_deposit: currentList[oldIndex].order_deposit,
                    order_withdrawal: currentList[indexEdited].order_withdrawal
                }
            }
        }

        if (data.order_deposit && data.order_withdrawal) {
            let oldIndexOne = currentList.findIndex((i) => i.order_withdrawal === parseInt(data.order_withdrawal as any));

            currentList[oldIndexOne] = {
                payment_system: currentList[oldIndexOne].payment_system,
                active_deposit: currentList[oldIndexOne].active_deposit,
                active_withdrawal: currentList[oldIndexOne].active_withdrawal,
                order_deposit: currentList[oldIndexOne].order_deposit,
                order_withdrawal: currentList[indexEdited].order_withdrawal
            }

            let oldIndexTwo = currentList.findIndex((i) => i.order_deposit === parseInt(data.order_deposit as any));

            currentList[oldIndexTwo] = {
                payment_system: currentList[oldIndexTwo].payment_system,
                active_deposit: currentList[oldIndexTwo].active_deposit,
                active_withdrawal: currentList[oldIndexTwo].active_withdrawal,
                order_deposit: currentList[indexEdited].order_deposit,
                order_withdrawal: currentList[oldIndexTwo].order_withdrawal
            }
        }

        let dataSend: PaymentSystemConfig = {
            payment_system: props.data.payment_system,
            active_deposit: data.active_deposit ?? currentList[indexEdited].active_deposit,
            active_withdrawal: data.active_withdrawal ?? currentList[indexEdited].active_withdrawal,
            order_deposit: parseInt(String(data.order_deposit)),
            order_withdrawal: parseInt(String(data.order_withdrawal)),
        }

        currentList[indexEdited] = dataSend;

        const savePut = await Config.put({
            data: {
                paymentsList: currentList,
            }
        })
        if(savePut && savePut.data) {
            toast.success(`${savePut.message}`);
        }
        if (props.onFinish) {
            props.onFinish();
        }
        setOpen(false);
        setLoading(false);
    }

    useEffect(() => {
        if(open) {
            reset();
        }
    }, [open]);

    const activeDeposit = watch('active_deposit');
    const activeWithdrawal = watch('active_withdrawal');

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Tooltip content="Comprovante">
                <Tooltip content="Editar">
                    <Dialog.Trigger>
                        <IconButton radius='full'>
                            <Edit size={18} />
                        </IconButton>
                    </Dialog.Trigger>
                </Tooltip>
            </Tooltip>

            <Dialog.Content maxWidth="500px">
                <Dialog.Title>{t(`Editar ${props.data.payment_system}`)}</Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* {loading &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                        <Spinner size={"3"} />
                    </div>
                } */}
                    <Flex direction="column" gap="3">
                        <Flex direction={'row'} justify={'between'}>
                            <Text>{t('Deposit')}</Text>
                            <Switch checked={activeDeposit} defaultChecked={props.data.active_deposit} onCheckedChange={(checked) => setValue('active_deposit', checked)} />
                        </Flex>

                        <Flex direction={"row"} justify={'between'}>
                            <Text>{t('Withdraw')}</Text>
                            <Switch checked={activeWithdrawal} defaultChecked={props.data.active_withdrawal} onCheckedChange={(checked) => setValue('active_withdrawal', checked)} />
                        </Flex>

                        <Text>{t('Order_Deposit')}</Text>
                        <Controller
                            control={control}
                            name="order_deposit"
                            defaultValue={props.data.order_deposit ?? 1}
                            render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                <Select.Root value={String(value)} onValueChange={onChange}>
                                    <Select.Trigger />
                                    <Select.Content>
                                        <Select.Group>
                                            <Select.Label>{t('Select_Type')}</Select.Label>
                                            {Array.from(Array(props.paymentsCount).keys()).map((i, index) => {
                                                return (
                                                    <Select.Item key={index} value={String(i + 1)}>{i + 1}</Select.Item>
                                                )
                                            })}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            )}
                        />
                        {errors.order_deposit &&
                            <p style={{ color: 'red', fontSize: 12 }}>
                                {t('Type_Required')}
                            </p>
                        }

                        <Text>{t('Order_Withdraw')}</Text>
                        <Controller
                            control={control}
                            name="order_withdrawal"
                            defaultValue={props.data.order_withdrawal ?? 1}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                <Select.Root value={String(value)} onValueChange={onChange}>
                                    <Select.Trigger />
                                    <Select.Content>
                                        <Select.Group>
                                            <Select.Label>{t('Select_Type')}</Select.Label>
                                            {Array.from(Array(props.paymentsCount).keys()).map((i, index) => {
                                                return (
                                                    <Select.Item key={index} value={String(i + 1)}>{i + 1}</Select.Item>
                                                )
                                            })}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            )}
                        />
                        {errors.order_withdrawal &&
                            <p style={{ color: 'red', fontSize: 12 }}>
                                {t('Type_Required')}
                            </p>
                        }
                    </Flex>

                    <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                            <Button variant="soft" color="gray">
                                {t('Close')}
                            </Button>
                        </Dialog.Close>
                        {!loading && <Button type="submit">Save</Button>}
                        {loading && <Button disabled>
                            <Spinner />    
                        </Button>}
                    </Flex>
                </form>
            </Dialog.Content>
        </Dialog.Root>

    );
}

export default ModalPaymentConfig;