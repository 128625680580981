import { createUseStyles } from 'react-jss';
import { color } from 'src/utils';

const useHeaderStyles = createUseStyles({
    HeaderInner: {
        minWidth: 320,
        position: 'fixed',
        height: 70,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        userSelect: 'none',
        transition: 'background-color .18s,box-shadow .18s',
        backgroundColor: color('color-panel'),
        boxShadow: '0 1px var(--gray-a4)',
    },
    LeftFlex: {
        position: 'absolute',
        paddingLeft: 16,
        left: 0,
        bottom: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center'
    },
    LeftFlexAbsolute: {
        gap: 12,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        boxSizing: 'border-box'
    },
    BoxLink: {
        display: 'block',
        outline: 0,
        padding: 4,
        margin: 4,
        borderRadius: 3,
        color: 'inherit',
        cursor: 'pointer'
    },
    HeaderProductLinks: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 'calc(50% + 100px)'
    },
    HeaderProfileMenu: {
        position: 'absolute',
        paddingRight: 4,
        bottom: 0,
        margin: 15,
        right: 0,
        top: 0,
        gap: 10,
        alignItems: 'center',
        display: 'flex'
    }
})

export default useHeaderStyles;